import { React, useState, useEffect, useRef } from "react";
import { useHistory, Redirect } from 'react-router-dom';
import axios from 'axios';
import { getJwt } from './jwt';
import MongoPost from './autoCompPostBtt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faHouse, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from 'react-toastify';
import Rating from "./rateProperty";
import 'react-toastify/dist/ReactToastify.css';
// import { notify } from 'react-notify-toast';
import { Helmet } from "react-helmet-async";
import '../css/styles.css'


const AutoCompPost = (props) => {
  const propImages = props
  const addImages = propImages.images
  const addComments = propImages.comment
  const addRecommendations = propImages.recommend
  const addRatings = (propImages.rating)
  console.log(addRatings);

  const history = useHistory();


   const [showSuggestions, setShowSuggestions] = useState([]);
   const [postCode, setPostcode] = useState ("");
   const [filtSuggestions, setFiltSuggestions] = useState([]);
   const [compState, setCompState] = useState ("");
   const [splitAddress, setSplitAddress] = useState ([]);
   const [postcodeAddresses, setPostcodeAddresses] = useState([]);
   const [addressId, setAddressId] = useState([]);
   const [comment, setComment] = useState ("");
   const [recommendation, setRecommendation] = useState ("");
   const [images, setImages] = useState ([]);
   const [images1, setImages1] = useState ([]);
   const [images2, setImages2] = useState ([]);
   // const removeWhitespace = (postCode: string) => {
   //      return postCode.replace(/\s/g, "")};
   const jwt = getJwt();
console.log(jwt);
const noJwt = () => {
if (!jwt) {
<Redirect to='/login.validate'/>

}}

noJwt()

   useEffect(() => {


     
     const loadAddresses = async () => {
       const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/user/getaddress/${postCode}`, { params: {filter:postCode, all: true}});
         setShowSuggestions(response.data.suggestions)
         setFiltSuggestions(response.data.suggestions)
         setPostcodeAddresses(response.data.suggestions)
         console.log(response);
        //  console.log(postCode);
          }

   loadAddresses();

 }, [postCode])


 const onSuggesthandler = (selectedAddress) => {
   setPostcode(selectedAddress);
   setCompState (selectedAddress)
     setPostcodeAddresses(selectedAddress)
 }

const initialiseState = (selectedAddress) => {
  
    console.log('%c Address Match Str','color:green; font-weight:bold;');
    console.log(selectedAddress)
    console.log(compState)

    setShowSuggestions([])

  if (compState && compState.length >0) {
    console.log('%c Clear Suggestions fired','color:green; font-weight:bold;');
    console.log('Clear Suggestions fired')
    setTimeout
  (() => {
    setFiltSuggestions([])
  },
  400)
  }
}

  const onChangeHandler = (postCode) => {
    let matches = []
    if (postCode.length>0) {
       matches = filtSuggestions.filter(suggestion=>{
        // const regex = new RegExp (`${postCode}`,"gi")
        return setFiltSuggestions
      })
    }
    console.log('matches', matches);
    setFiltSuggestions(matches)
    setPostcode(postCode)

  }
   



// const fetchAddress = (event) => {
//   //Get the address in  corrWhat ect format
//   event.preventDefault();
//   const id = toast.loading("Uploading Review...Please wait")
//     const API_URL = `https://api.getAddress.io/get/${addressId}?api-key=${API_KEY}`;
//     axios.get(API_URL)
//       .then((response) => {
        
//         var address = response.data;
//         const line1 = address.line_1;
//         const line2 = address.line_2
//         const line3 = address.line_3
//         const town = address.town_or_city
//         const county = address.county
//         const postcode = address.postcode
//         let image = [...images]

//         console.log(addImages.map((img,cur) => img.toString().replace(/[_\)\(\s]/g,"-")))

//         console.log(image);
//         console.log(addRatings);



//         setSplitAddress(response.data);
//         setAddressId(addressId)
//         console.log(addressId);

//         // get our form data out of Get response
//         const formData = new FormData()
  

//         Object.keys(addImages).forEach((key) => {
//           if (addImages[key] instanceof FileList) {
//             console.log('%c AddImage','color:red; font-weight:bold;')
//             console.log(formData.get(addImages));
//             formData.append('images', addImages[key][0], addImages[key][0].name);
//           } else {
//             formData.append('images', addImages[key]);
//           }
//         });

//         formData.append('line1', line1);
//         formData.append('line2', line2);
//         formData.append('line3', line3);
//         formData.append('town', town);
//         formData.append('county', county);
//         formData.append('postcode', postcode);
//         formData.append('addressId', addressId);
//         formData.append('comments', addComments);
//         formData.append('recommendations', addRecommendations);
//         formData.append('ratings', addRatings);
//         formData.append('images', addImages);

//         // formData.append('images', JSON.stringify(images));
//         // formData.append('images1',images1);
//         // formData.append('images2',images2);

//         // const jwt = getJwt();
//         let config = {
//           headers: {
//             'Content-Type': 'multipart/formData',
//             'Authorization' : 'Bearer ' + jwt

//           }
//         }

//         console.log(formData.get('ratings'))

//         axios.post(process.env.REACT_APP_API_ADDRESS + '/tenants/rate', formData, config)
//         .then(response => {
//           toast.update(id, {render: "Review uploaded successfully", type: "success", isLoading: false})
//         console.log(response)

//       })

//       .catch((error) => {
//         const err = response.data.error
//         toast.update(id, {render: `'Error: ${error.response.data.error}'`, type: "error", isLoading: false })
//         console.log(error);
//       })

//   })
// }


const inputRef = useRef()

   return (
    <>
     <Helmet>
        <title>Write A Review</title>
        <meta name="description" content="Rate your experience as a tenant in a rented property" />
        <link rel="canonical" href="/post-review"/>
      </Helmet>

     <div className="rating">
     <form onSubmit={null}>
     <section className= 'enter-postcode'>
     <div className="card-address">
     <div className='cardie-home-icon'><FontAwesomeIcon icon={faHouse} size='xl' /></div>
      <div className="rate-address-input">
        <div className="rate-enter-postcode">
          <h3>Enter Postcode</h3>
        </div>
        <div className='rate-submit'>
          
          </div>
       <input
         type="text"
         className="input"
         onChange={e => onChangeHandler (e.target.value)}
         value={postCode}
      //    onBlur={()=> {
      //        setTimeout(()=> {
      //        setFiltSuggestions([])
      //      }, 200);

      //  }}
       />
       </div>
       {filtSuggestions && filtSuggestions.map((suggestion, index)=>
       <div key={index} id ="suggestions" className="suggestions"
       onClick={()=>{onSuggesthandler(suggestion.address); initialiseState(suggestion.address); setAddressId(suggestion.id);}}>{suggestion.address}</div>)}
       
     </div>
     </section>
    </form>
    <div>
    <Rating id = {addressId}/> 
    </div>
    <div>
      <ToastContainer/>
    </div>
     </div>
  </>
  )};

export default AutoCompPost;


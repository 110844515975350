import React, {Component} from "react";
import AutoCompPost from "./components/autoCompPost";
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Nav from './components/nav';
import Backdrop from './components/backdrops';
import SideDrawer from './components/sideDrawer/sideDrawer';
// import NavCard from './components/navCard';
import Rating from  "./components/rateProperty"
import AutoCompGet from  "./components/autoCompGet"
import AutoCardCompGet from  "./components/autoCardCompGet"
import FinalAddressRender from  "./components/finalAddressRendCopy"
import Confirm from './components/Confirm';
import ConfirmSub from './components/confirm-sub';
import  SignUp from  "./components/signup"
import { ProtectedRoute } from './components/protectedRoute';
import LoginValidate from './components/login.validate';
import AddressCardPreview from './components/addressCardPreview';
import AddressCardFinalAdd from "./components/addressCardFinalAdd";
import Abuse from "./components/Policies/abuse";
import Privacy from "./components/Policies/privacy";
import Cookies from "./components/Policies/cookies";
import Tenants from "./components/Policies/tenants";
import Landlords from "./components/Policies/tenants";
import Agency from "./components/Policies/agency";
import Terms from "./components/Policies/agency";
import ContactUs from "./components/contact";
import Use from "./components/Policies/acceptable_use";
import "./css/styles.css";
import Logout from "./components/logout";


class App extends Component {
  state = {
    sideDrawerOpen: false,
  }

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return {sideDrawerOpen: !prevState.sideDrawerOpen};
    });
  };

  backdropClickHandler = () => {
    this.setState({sideDrawerOpen: false});
  };

    backdropClickHandlerToo = () => {
    this.setState({sideDrawerOpen: false});
    console.log('This is a backdrop click')
    }
  

render() {


  let backdrop;

  if (this.state.sideDrawerOpen) {
    backdrop = <Backdrop click={this.backdropClickHandler} />
    console.log("Does this show when sidbar closed")
  }

  
  return (

    <div className='app-parent'style={{height: '100vh'}}>
      <Nav drawerToggleClickHandler={this.drawerToggleClickHandler} close={this.backdropClickHandlerToo}/>
      <Switch>
        
        {/* <Route path="/rate-property" exact component={Rating} /> */}
        <Route path="/" exact component={AutoCardCompGet} />
        <Route path="/home" component={AutoCardCompGet} />
        <Route path="/user-review" exact component={FinalAddressRender} />
        <Route exact path='/confirm/:id' component={Confirm} />
        <Route exact path='/confirm-subscription/:id' component={ConfirmSub} />
        <Route exact path="/login" component={LoginValidate} />
        <Route path="/signup" component={SignUp} />
        <Route path="/report-abuse" component={Abuse} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/cookies" component={Cookies} />
        <Route path="/tenant-tcs" component={Tenants} />
        <Route path="/landlord-tcs" component={Landlords} />
        <Route path="/agency-tcs" component={Agency} />
        <Route path="/tcs" component={Terms} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/acceptable-use" component={Use} />
        <Route path="/user-review-preview" render={(props)=> <AddressCardPreview {...props}/>} />
        <Route path="/addressCardFinalAdd" component={AddressCardFinalAdd}/>
        <Route path="/logout" component={Logout} />
        <ProtectedRoute path="/post-review" component={AutoCompPost}/>
        <Route path="*" component={() => "404 NOT FOUND"} />

      </Switch>
      <SideDrawer show={this.state.sideDrawerOpen} />
        {backdrop}
    </div>

    )
  }
}

  export default App;

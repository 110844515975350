import {React, useEffect, useState} from 'react';
import '../css/nav.css';
import { Link, useLocation } from 'react-router-dom';
import DrawerToggleButton from './sideDrawer/drawerToggleButton';
import Logo from '../assets/Rate Rental Logo-7.png';
import AutoCompPost from './autoCompPost';
import {removeToken} from './logout';
// import DrawerToggleButton from './sideDrawer/drawerToggleButton';
// import {removeToken} from './logout';
function Nav(props) {

  const [menuItem, setMenuItem] = useState(false)

  const saved = localStorage.getItem('user')

  const location = useLocation()

  const bool = props.navProp

  const {close} = props
  console.log(bool)

  useEffect ((props) => {
    if (saved) {
      setMenuItem(true)
    }
    
    close()
    console.log("location",location)

  },[location])

  // useEffect (() => {
  //   if (bool.length > 0) {
  //     setMenuItem(false)
  //   }

  //   console.log('bool is false')

  // },[bool])

  return (
    <div>
    <header className="toolbar">
    <nav className="toolbar_navigation">
    <div className='logo-container'>
      <div className="toolbar_logo"><Link to="/home"><img src={Logo} width={180} height={40}></img></Link></div>
      </div>
      <div className='toolbar_toggle-button'>
        <DrawerToggleButton click= {props.drawerToggleClickHandler} />
      </div>
      <div className="spacer"></div>
      <div className="toolbar_navigation-items">
      <ul>
        <Link
        to="/home" className="toolbar_navigation-items">
          <li>Home</li>
        </Link>
        <Link to="/post-review" className="toolbar_navigation-items">
          <li>Rate</li>
        </Link>
        <Link to="/signup" className="toolbar_navigation-items">
        <li>Signup</li>
        </Link>
        <Link to="/login" className="toolbar_navigation-items">
          <li>Login</li>
        </Link>
        <Link to="/logout" className="toolbar_navigation-items">
          { menuItem ? <li>Logout</li> : null}
        </Link>
        <Link to="/#" className="toolbar_navigation-items">
        </Link>
      </ul>
      </div>
    </nav>
    </header>
    </div>
  );
}

export default Nav;

import React from 'react'
import { useState, useEffect, useRe } from "react";
import {useLocation, useHistory} from "react-router-dom";
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import FinalAddressRend from './finalAddressRend'
import Logo from '../assets/Rate Rental Logo-7.png';
import Footer from './footerNew';
import ReactPlayer from 'react-player/lazy'
import { Card, Row, Col, Container } from "react-bootstrap";
import {FaStar} from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faHouse, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from 'react-helmet-async';
import '../css/styles.css'



const AddressCardFinalAdd = () => {
  const [theAddress, setAddress] = useState([])
  const [theFinAddress, setFinAddress] = useState([])
  const [userIndex, setUserIndex] = useState([])
  const [user, setUser] = useState(false)
  const [address, setTheAddress] = useState({})

  const {state} = useLocation()
  console.log(state)

  


  useEffect(() => {
  const usr = state.users
  console.log(usr)
  setAddress(usr)
    console.log("add",theAddress);
  const add = state.address
  setTheAddress(add);
  },[])

  useEffect(() => {
  const ind = state.comms
  setUserIndex(ind)
    console.log("user",userIndex);
  },[])

  useEffect(() => {

  if (theAddress && theAddress.length >0) {
  setUser(true)
  }
  deleteHandler(userIndex)
    console.log("user",userIndex);
  },[theAddress])

  useEffect(() => {

  if (userIndex && userIndex.length >0) {
  deleteHandler(userIndex)
  console.log("user",userIndex);}
},[user])

  const deleteHandler = (userIndex) => {
  // Assuming that the index means the index of an item which is to be rendered.
  const newList = theAddress.filter((item) => theAddress.indexOf(item) == userIndex);
  setFinAddress(newList);
}



//       //Filter and map selected user card for url strings that end with .mp4. Store them in the 'match' variable
//       // Use the 'match' variable for rendering user videos

      const match = []

      let filteredArray = theFinAddress.map((element) => Object.values((element.imagess).
      filter(function(str){
        return str.endsWith('.mp4')
      }).map(function(str) {
        return match.push(str)
      }

      )));
      
      
      console.log(match)




    return (
      <div>
        <section>
        <div className='card-address cardie-2'>
          <div className='cardie-home-icon'><FontAwesomeIcon icon={faHouse} size='xl' /></div>
            <h2>{address.line1}</h2>
            <h2>{address.town}</h2>
            <h2>{address.postcode}</h2>
          </div>
        </section>
        <section>
        <div className='user-container'>
          <div className='user-box'>
          {theFinAddress.map((add, i) => (
        <div key={i}>
        <div className='final-user'><span>{<FontAwesomeIcon icon={faUser} size="xs" />}</span>
          {add.username}<br/>
        {[...Array(5)].map((star, index) => {

const ratingValue = index + 1


  return (
    <>
      <Helmet>
        <title>Tenant Reviews</title>
        <meta name="description" content="Tenant Review Search Results" />
        <link rel="canonical" href="/user-review"/>
      </Helmet>

    <label key ={index}>
      <input
        type ="hidden"
        name = "rating"
        value = {add.rating}
        />

      <FaStar key ={index} color={ratingValue <= add.rating ? "#ffc107" : "#e4e5e9"} size={15} />
    </label>
  </>)
  })}
      <hr ></hr>

      </div >
        
        
       
            <div className='recommend-prompt'>
            Would you recommend here: <br/>
            </div>
            <br/>
            <div className='user-recommend-box'>
             {add.recommend}, <br/>
             </div>
            <br/>
          <div className='comment-prompt'>
            Your Comments: <br/>
            </div>
            <br/>
          <div className='user-comment-box'>
            {add.comment}, <br/>
          
          </div>
          <br/>
          <div>{add.imagess.length ?
            <div>{add.imagess.filter((images, current) => !images.includes('.mp4') && !images.includes('/thumb00001.png')).map((img, cur) => 
              <div className='user-images' key={cur}>
                  <img className='user-img' src={img} />
                </div>
          )}</div> 
            : null
            }

          </div>
        </div>
        
      
        
      )
      )}
      <br/>
            <div className='video'>
        {match.map((add, i) =>
        <div key={i}>
        <div className='player-wrapper'>
        <ReactPlayer
        className='react-player'
         controls url={add}
         width='100%'
          height='100%'/>
        </div> 


        </div>
      
    )
  }
  </div>
        </div>
        
        </div>
        
        </section>
        <section className="foot-container">
     <div>
        <Footer/>
      </div>
     </section>
     

  </div>
  )
}
  



// AddressCardFinalAdd.propTypes = {
//   comms:PropTypes.number.isRequired,
//   users: PropTypes.arrayOf(PropTypes.object.isRequired)
//   };


export default AddressCardFinalAdd

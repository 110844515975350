import React from 'react';
import { Link } from 'react-router-dom';
import './sideDrawer.css';


const sideDrawer = props => {

  let drawerClasses = 'side-drawer';
  if (props.show) {
    drawerClasses = 'side-drawer open';
  }
  return (

    <nav className={drawerClasses}>
    <ul>
    <Link to="/home">
      <ul>
        <li style={{ listStyleType: "none" }}>Home</li>
      </ul>
    </Link>
    <Link to="/post-review">
      <ul>
        <li style={{ listStyleType: "none"  }} padding='0' margin='0'>Rate</li>
      </ul>
    </Link>
    <Link to="/signup">
      <ul>
        <li>Signup</li>
      </ul>
    </Link>
    <Link to="/login">
      <ul>
        < li>Login</li>
      </ul>
    </Link>
    <Link to="/logout">
      <ul>
        <li>Logout</li>
      </ul>
    </Link>
    </ul>
  </nav>
);

};

export default sideDrawer;

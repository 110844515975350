import React from 'react';
import PropTypes from 'prop-types';
import AddressCardPreview from './addressCardPreview';
import { useHistory } from 'react-router-dom';


const SignupCardRed = () => {
    const history = useHistory()
    const navigateTo = () => history.push('/signup');//eg.history.push('/login');

    return (

    <button className="btn btn-primary form-control" onClick={navigateTo}>
      Signup Here!
    </button>
)
};

// MongoCardPost.propTypes = {
//   post: PropTypes.array,
// };

export default SignupCardRed ;

import React, { Component } from 'react';
import './drawerToggleButton.css';

const drawerToggleButton = props => (
  <button className="toggle-button" onClick={props.click} aria-label='Collapsed Navigation Bar'>
    <div className="toggle-button_line" />
    <div className="toggle-button_line" />
    <div className="toggle-button_line" />
  </button>

);

export default drawerToggleButton;

import React, { Component } from 'react';
// import { notify } from 'react-notify-toast';
import { Link, Redirect } from 'react-router-dom';
import GoogleSignup from './signupGoogle';
import Footer from './footerNew';
import { GoogleLogin } from '@react-oauth/google';
import Logo from '../assets/Rate Rental Logo-7.png';
import '../css/styles.css';
import axios from 'axios';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Helmet } from 'react-helmet-async';


const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const formValid = ({ formErrors, username, email, password, confirmPassword, confirmPasswordMatch }) => {
  let valid = true;

  // validate form errors being empty
  Object.values(formErrors).forEach(val => {
    val.length > 0 && (valid = false);
  });

  // validate the form was filled out
  Object.values({ username, email, password, confirmPassword }).forEach(val => {
    val === null && (valid = false);
  });

  let pass = password
  let conf = confirmPassword
  let error ="Passwords don't match."

  if ( pass !== conf) {
      valid = false;
       console.log (error);
    }



  return valid;
};



class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstname: "",
      lastname: "",
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      redirect: null,
      formErrors: {
        username: "",
        email: "",
        password: "",
        confirmPassword:""

      }
    };
  }

  showToastMessageSignup = (message) =>{
    const toastId = 'fetched-nationalities';
    toast.warning(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000, //3 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      toastId,
      transition: Slide
    });
    // console.log('This is the show toast func')
    console.log(message)
  };

  showToastMessageEmailSent = (message) =>{
    const toastId = 'fetched-nationalities';
    toast.info(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000, //3 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      toastId,
      transition: Slide
    });
    // console.log('This is the show toast func')
    console.log(message)
  };
  
  redirect =(message) => {
    if (message.toString().includes('Email sent, please check your inbox to confirm')) {
      setTimeout(()=> {this.changeShow()}, 5000)
      console.log('This should redirect')
    } else {
      console.log('No redirect here')}

  }
  

  signup = () => {

    const data = {
  firstname: this.state.firstname,
  lastname: this.state.lastname,
  username: this.state.username,
  email: this.state.email,
  password: this.state.password
}

      axios.post(`${process.env.REACT_APP_API_ADDRESS}/user/signup`, data)
        .then((response) => {
          const data = response.data;
          const dataTo =response.data.message
          const dataToo = response.data.msg
          console.log(response);
          this.showToastMessageSignup(dataTo)
          this.showToastMessageEmailSent(dataToo)
          this.redirect(dataToo)
        
        })
        .catch((error) => {
          console.log(error);
        });

        // this.changeShow();



    };

    changeShow() {
      this.setState({
        redirect: true
      })
      }

//       renderRedirect = () => {
//    if (this.state.redirect) {
//      return <Redirect to='/post-review' />
//    }
//  }


  handleSubmit = e => {
    e.preventDefault();


    if (formValid(this.state)) {
this.signup();
      console.log(`
        --SUBMITTING--
        Firstname:${this.state.firstname}
        Lastname:${this.state.lastname}
        Username: ${this.state.username}
        Email: ${this.state.email}
        Password: ${this.state.password}
      `);
    } else {
      console.error("FORM INVALID - DISPLAY ERROR MESSAGE");
    }
  };

  handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = { ...this.state.formErrors };

   const showToastErrorMessage = (length) => {
   const value = this.state.formErrors.username
    if(value.length < 3) 
      toast.error("minimum 3 characaters needed", {
          position: toast.POSITION.TOP_RIGHT
      });
  };

    switch (name) {
      case "username":
        formErrors.username =
          value.length < 3 ? "minimum 3 characaters required" : "";
        break;
      case "email":
        formErrors.email = emailRegex.test(value)
          ? ""
          : "invalid email address";
        break;
      case "password":
        formErrors.password =
          value.length < 6 ? "minimum 6 characaters required" : "";
        break;
        case "confirmPassword":
          formErrors.confirmPassword =
            value.length < 6 ? "please confirm your password"
            : value !== this.state.password ? "passwords do not match"
            : "";
          break;
      default:
        break;


    }

    this.setState({ formErrors, [name]: value }, () => console.log(this.state));
  };

  


  render() {


    const { formErrors } = this.state;
    if (this.state.redirect) {
      return <Redirect to='/post-review' />}

    return (
      <>
      <Helmet>
        <title>Sign Up</title>
        <meta name="description" content="Create an account and rate your experience as a tenant in a rented property" />
        <link rel="canonical" href="/signup"/>
      </Helmet>

      <div className="wrapper">
        <div><ToastContainer/></div>
        <div className='signup'>
        <h2>Sign Up With Google</h2>
        
        <GoogleSignup

          scope="openid profile email"/>
        </div>
        <div className='signup-or'>
          <h2><span>or</span></h2>
        </div>
        <div className='signup-email'>
          <h4>Signup With Email</h4>
        </div>
        <div className="form-wrapper">
          <form className='foot-form'  onSubmit={this.handleSubmit} noValidate>
            <div className="line">
            <label htmlFor="input"></label>
              <div className="input">
              <input
                // className={formErrors.email.length > 0 ? "error" : null}
                placeholder="Firstname"
                type="firstname"
                name="firstname"
                noValidate
                size="30"
                onChange={this.handleChange}
              />
              {/* {formErrors.email.length > 0 && (
                <span className="errorMessage">{formErrors.email}</span>
              )} */}
              </div>
              </div>
              <div className='line'>
              <label htmlFor="input"></label>
              <div className="input">
              <input
                // className={formErrors.email.length > 0 ? "error" : null}
                placeholder="Lastname"
                type="lastname"
                name="lastname"
                noValidate
                size="30"
                onChange={this.handleChange}
              />
              {/* {formErrors.email.length > 0 && (
                <span className="errorMessage">{formErrors.email}</span>
              )} */}
              </div>
              </div>
               <div className="line">
              <label htmlFor="input"></label>
                <div className="input">
              <input
                className={formErrors.username.length > 0 ? "error" :  null}
                placeholder="Username"
                type="text"
                name="username"
                noValidate
                size="30"
                onChange={this.handleChange}
              />
              {formErrors.username.length > 0 && (
                <div>
                <span className="errorMessage">{formErrors.username}</span>
                </div>
              )}
              </div>
              </div>
              <div className='line'>
              <label htmlFor="input"></label>
              <div className="input">
              <input
                className={formErrors.email.length > 0 ? "error" : null}
                placeholder="Email"
                type="email"
                name="email"
                noValidate
                size="30"
                onChange={this.handleChange}
              />
              {formErrors.email.length > 0 && (
                <span className="errorMessage">{formErrors.email}</span>
              )}
              </div>
            </div>
            <div className="line">
              <label htmlFor="input"></label>
              <div className="input">
              <input
                className={formErrors.password.length > 0 ? "error" : null}
                placeholder="Password"
                type="password"
                name="password"
                size="30"
                noValidate
                onChange={this.handleChange}
              />
              {formErrors.password.length > 0 && (
                <span className="errorMessage">{formErrors.password}</span>
              )}
              </div>
            </div>
            <div className="line">
              <label htmlFor="input"></label>
              <div className="input">
              <input
                className={formErrors.password.length > 0 ? "error" : formErrors.confirmPasswordMatch ? "error" : null}
                placeholder="Confirm Password"
                type="password"
                name="confirmPassword"
                size="30"
                noValidate
                onChange={this.handleChange}
              />
              {formErrors.confirmPassword.length > 0 && (
                <span className="errorMessage">{formErrors.confirmPassword}</span>
              )}
              </div>
            </div>
            <div className='button-center-2'>
            <button className = "btn btn-primary form-control" type="submit" size="30" name="input">Create Account</button>
            </div>
            <div className='button-text'>
            <Link to='/login'>Already Have an Account?</Link>
            </div>
          </form>
        </div>
        <div>
          { this.state.redirect ?
          <div>{this.showToastMessageEmailSent()}</div> :
          null

          }
        </div>
        <section className='foot-container'>
     <div className="foot">
        <Footer/>
      </div>
     </section>
      <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
      </div>
   </> );
  }
}


export default SignUp;

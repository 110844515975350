import React, { useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import '../css/styles.css';


function GoogleSignup() {
const [redirect, setRedirect] = useState (false)
let userId = {}
const history = useHistory()

function changeShow() {
    if (localStorage.getItem('user')) {
        return history.push({
            pathname: "/post-review"
        })   
    }}
    // function renderRedirect () {
    //     if (redirect) {
    //      return <Redirect to='/rateProperty' />
    //     }
    //     }

    function handleCallBackResponse (response) {
        console.log("Encoded JWT Token" + response.credential);
        let token = response.credential
        let userObj = jwt_decode(response.credential)
        console.log(userObj);
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_ADDRESS}/user/googlelogin`,
            data: {token}
        }).then(response => {
            console.log("Google login success",response);
            localStorage.setItem('user', response.data.token);
            localStorage.setItem('username', response.data.username)
            userId = (response.data.userId)
            console.log(userId)
            changeShow()
        }).catch (error => {
            console.log(error)

        })
    }
useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
        client_id: "794670595096-6njgg7a4ft60gtqihv7p9knpnjvieivl.apps.googleusercontent.com",
        callback: handleCallBackResponse
    });
    google.accounts.id.renderButton(
        document.getElementById("signInDiv"),
        {theme: "outline", size: "large"}
    )
},[]);

// useEffect(() => {
//     if(redirect)
//     renderRedirect()

// },[redirect])

return (
    <div className='google-signin'>
        <div className='signInDiv' id="signInDiv"></div>
            {/* { user && 
                <div>

                </div>

            } */}
    </div>
)
}
export default GoogleSignup;
import React,{ Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {FaStar} from "react-icons/fa";


const StarRating = (props) => {
  const [avgNums, setAvgNums] = useState(0)
  const [avgNum, setAvgNum] = useState([])
  const def= 0
  const [nums, setNums] = useState([def])


  const rates = {...props}
  const rate = rates.rate


  // const rate = rates.rating
  // console.log(rate);

useEffect(() => {

  function getAverage (ratings) {
    if (ratings && ratings.length > 0) {
      let sum = 0
     for( var i = 1; i < ratings.length; i++ ){
         sum += ( ratings[i], 5 );
     }
    
     var avg = sum/ratings.length;
     console.log(avg);
     var avgRounded = Math.round(avg)

     console.log(avgRounded)
     return setAvgNum(avgRounded);



  }}
  setNums(rate)
  getAverage(nums)
  console.log("arrayNums", nums);
},[nums])
// setAvgNums({...ratingNums})






  return (
    <div className='overall-rating'>
    <h2> Reviews Rating </h2>
    <div>
      {[...Array(5)].map((star, index) => {

        const ratingValue = index + 1


        return (
          <label key ={index}>
            <input
              type ="hidden"
              name = "rating"
              value = {avgNum}
              />

            <FaStar key ={index} color={ratingValue <= avgNum ? "#ffc107" : "#e4e5e9"} size={30} />
          </label>
        )
      })
    }


    </div>
  </div>)
}





export default StarRating

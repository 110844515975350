import React from "react";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet-async";



function Abuse () {

    return (
		<>
		<Helmet>
        <title>Abuse Policy</title>
        <meta name="description" content=" The abuse policy describing how to report abusive behaviour on this site" />
        <link rel="canonical" href="/report-abuse"/>
        </Helmet>


        <div className="policy-row">
			<div className="small-12 columns">
				<p><Link to="/home">www.raterental.co.uk</Link> <strong>(our site) </strong>is a site operated by Rate Rental Limited <strong>(we or us)</strong>.</p>
<p>In addition, our website <Link to="/tc's">terms of use</Link> and acceptable <Link to="/acceptable-use">use policy</Link> apply to all users of / visitors to our site, and these prohibit (amongst other things)<span><span>&nbsp</span></span>;abuse of the review mechanism on our site by those users / visitors.</p>
<p>Although reasonable efforts have been made to ensure that inappropriate material will not be present on our site or used in connection with it, the<span>&nbsp</span>;risk cannot be entirely eliminated.</p>
<p>In particular, we recognise that from time to time reviews or other material may be posted on our site by third parties which is in breach of our<span>&nbsp</span>;website terms of use and acceptable use policy.<span>&nbsp</span>;If you are concerned that you have found material on our site which is inappropriate, fraudulent, defamatory, inaccurate, infringes third party intellectual property rights, or which is in any other way in breach of our website terms of use and acceptable use policy, or in breach of the law,<span>&nbsp</span>;please report this to us immediately on <a href="mailto:reportareview@raterental.co.uk">reportareview@raterental.co.uk</a>.</p>
<p><strong>Complaining about Defamatory Content:</strong></p>
<p>Statements that are true or that reflect an honest opinion (e.g. negative review of a landlord or a property) are not usually themselves a basis for defamation. As per Guidance on Section 5 of the Defamation<br/>
Act 2013 and Regulations, you <strong>must confirm</strong> that you <strong>do not have sufficient information</strong> about the person who posted the review to bring proceedings directly against them. <span>&nbsp</span>;<span>&nbsp</span>;You should carefully consider the facts before you make a complaint (and consider taking legal advice if you have any doubts as to whether the words you are unhappy with are in fact defamatory). We will manage the defamatory complaint in line with <a href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/269138/defamation-guidance.pdf">Section 5 of the Defamation Act 2013 and Regulations – January 2014:<span>&nbsp</span>;</a></p>
<p>If you wish to notify Rate Rental Ltd of an allegedly defamatory statement contained on raterental.co.uk (<strong>the website)</strong> please complete the ‘Notification of Defamatory Content’ prescribed below and send it by email to <a href="mailto:reportareview@raterental.co.uk">reportareview@raterental.co.uk</a>. You must also include your email address and name where you can be contacted. The content below is prescribed in Section 5 of the Defamation Act and must be adhered to.</p>
<p><strong>Prescribed of Notification to Rate Rental Limited of Defamatory Content (as in line with Section 5 of Defamation Act): </strong></p>
<p>I [state your full name] of [state your Name and email address], say as follows:</p>
<ol>
<li>I refer to the Rate Rental Ltd website www.raterental.co.uk (the “Website”) and now give Notification to Rate Rental Ltd that via its Website it is enabling or contributing to the publication of a defamatory statement.</li>
<li>The relevant statement appears on the Website at [please cut and paste the review or comment in which the relevant statement appears and the address of the web page from the Website where the defamatory words appear]. Please also add the URL showing where does the review sit on the website.</li>
<li>The words I consider defamatory are: [please repeat the exact words appearing on the Website which you are complaining about].</li>
<li>I submit that these words are defamatory because [please state why you consider these words to be defamatory].</li>
<li>I consider these words defamatory [please state why the words complained of are untrue and what you believe the true position is].</li>
<li><strong>I also confirm that I do not have sufficient information about the person who posted the statement to bring proceedings against that person</strong>.</li>
<li>I confirm that Rate Rental Ltd <em>can share</em> / <em>must not share</em> my email address and contact details with the poster of the review.</li>
</ol>
<p>I declare the above claim to be true, and understand that this Notification may be used in any subsequent court proceedings relating to the above defamation complaint.</p>
<p>[Your Name]</p>
<p>[If you are acting for the claimant, the claimant name]</p>
<p>We will look into the matter promptly, and judge each case on its merits. Where we receive the complaint about the defamatory review we will follow the process outlined in Guidance on <a href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/269138/defamation-guidance.pdf">Section 5 of the Defamation Act 2013 and Regulations</a>. We may temporarily remove the material in question from our site while<span>&nbsp</span>;we look into the issue. We reserve the right where necessary to seek professional legal advice on the matter, and to take legal action in<span>&nbsp</span>;connection with it if appropriate. Where relevant, we will contact any contributor or other third party who supplied or made available the material<span>&nbsp</span>;in question.</p>
<p>Following our investigation into the complaint is reported to us, we will take whatever steps we regard as reasonable to address the issue. Depending<span>&nbsp</span>;on our findings, we may at our discretion do one or more of the following:</p>
<ul>
<li>replace the material on our site unchanged;</li>
<li>replace the material on our site with such changes as we, acting reasonably, regard as fair and necessary;</li>
<li>remove the material permanently from our site.</li>
</ul>

			</div>
		</div>
    </>
	);
}

export default Abuse
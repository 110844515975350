import React from 'react';
import PropTypes from 'prop-types';
import AddressPreview from './addressPreview';


const MongoPost = ({ post }) => (
  
    <div>
    <button className="btn btn-primary form-control" onClick={post}>
      Submit Review
    </button>
    </div>
   
 
);

// MongoPost.propTypes = {
//   addresses: PropTypes.array,
//   onAddressClick: PropTypes.func.isRequired
// };

export default MongoPost;

import React, { Component, useState, useEffect} from 'react';
import ReactPlayer from 'react-player/lazy'
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

const FinalAddressRender = ({ address, groupcoms }) => {
  const [img, setImages] = useState ([]);
  const arrayOfObjects = img
  const arrayOfImages = img

console.log(arrayOfImages);

  // const [filt, setFilt] = useState ([])
  useEffect(() => {

  setImages(groupcoms)
    console.log("pics",img);
  },[groupcoms])

//   const combinedItems = (arr = []) => {
//      const res = arr.reduce((acc, obj) => {
//         let found = false;
//         for (let i = 0; i < acc.length; i++) {
//            if (acc[i].author === obj.author) {
//               found = true;
//               acc[i].count++;
//            };
//         }
//         if (!found) {
//            obj.count = 1;
//            acc.push(obj);
//         }
//         return acc;
//      }, []);
//      return res;
//   }
//   console.log(filt());
//
// console.log();

  // var authors = [{ make: 'audi', model: 'r8', year: '2012' }, { make: 'audi', model: 'rs5', year: '2013' }, { make: 'ford', model: 'mustang', year: '2012' }, { make: 'ford', model: 'fusion', year: '2015' }, { make: 'kia', model: 'optima', year: '2012' }],
//     const result = groupcoms.reduce(function (r, a) {
//         r[a.author] = r[a.author] || [];
//         r[a.author].push(a);
//         return r;
//     }, Object.create(null));
//
// console.log(result);

  // const result = Object.values(groupcoms.reduce((acc, { 'author': author, comment, recommend, username, usrname }) => {
  //     acc[author] ??= { author, comment: [], recommend: [], username: [], usrname: [] };
  //     acc[author].comment.push(Comment);
  //     acc[author].recommend.push(Recommend);
  //     acc[author].username.push(Username);
  //     acc[author].usrname.push(Usrname);
  //
  //     return acc;
  // }, {}));

  // console.log(result);


// const filtUsr= groupcoms.map((current)=>{
//      return {...current, recommend:"username"}
//  })
//  setFilt(filtUsr)



      let rate = address.ratings;




      // const Emage = ({img}) => (
      //   <div>
      //     {img.map(image => (
      //       <div className="station" key={image.imagess}>{JSON.stringify(image.imagess)}</div>
      //     ))}
      //   </div>
      // );

      const listRatings =
      rate.map((rating, index) =>
        <li key={index}>
          {rating}
        </li>
      );

      console.log(groupcoms);

      return (
        <>
        <Helmet>
        <title>Tenant Reviews</title>
        <meta name="description" content="Tenant Review Search Results" />
        <link rel="canonical" href="/user-review"/>
        </Helmet>
      <div>
        <div>
        {address.line1}<br />
        {address.line2}<br />
        {address.line3}<br />
        {address.postcode}<br />
        {address.town}<br />
        <div>
          {listRatings}
        </div>
        <div>
        {arrayOfObjects.map(({ username, comment, recommend, imagess }) => (
       <div key={username}>
        <div>
        Username:{username}<br/>
        Comment:{comment}<br/>
        Recommendation:{recommend}<br/>
        Images:
        <div url={imagess}>
        <ReactPlayer controls url={imagess}/>
        </div>
        </div>
       </div>
     ))}
     <div>
     <div>


     </div>

     </div>
    <div>
    </div>






        </div>
        </div>
  </div>
</>)
};



FinalAddressRender.propTypes = {
  address: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    line1: PropTypes.string.isRequired,
    line2: PropTypes.string.isRequired,
    line3: PropTypes.string.isRequired,
    postcode: PropTypes.string.isRequired,
    town: PropTypes.string.isRequired,
    comments:PropTypes.arrayOf(PropTypes.object.isRequired),
    ratings:PropTypes.arrayOf(PropTypes.number.isRequired)
  })
  };


export default FinalAddressRender

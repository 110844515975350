import { useRef, useMemo, useState, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import '../css/custom.css'
import usePlacesAutocomplete, {
  getGeocode,
  getgeocodeByAddress,
  getLatLng,
  geocodeByPlaceId
} from "use-places-autocomplete";



const libraries = ["places"]

const Home = ({pst}) => {
  const [selected, setSelected] = useState (null)
  const [address, setAddress] =useState(null)
  const addressRef = useRef(address)
  // const addressRef = React.createRef();
  // addressRef.current = address;
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    const componentRestrictions = {
      country: "GB"}
    const results = await getGeocode({ address, componentRestrictions });
    const { lat, lng} = await getLatLng(results[0]);

    console.log(lat);
    console.log(lng);
    setSelected({ lat, lng });
  }

  useEffect((address) => {
const pstCode = pst.replace(/\s/g, "")
const finCode = pstCode
setAddress(finCode)

},[pst])

useEffect(() => {
addressRef.current = address
},[address])

useEffect(() => {
  console.log(`${addressRef.current}`);
handleSelect(`${addressRef.current}`)
},[address])


  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  if (!isLoaded) return <div>Loading...</div>;

  return <Map />;



function Map() {
  const center = useMemo(() => ({ lat: 51, lng: -0.11 }), []);
  // const [selected, setSelected] = useState(null);

  return (
    <>
      <GoogleMap
        zoom={16}
        center={selected}
        options = {{
        gestureHandling: "greedy"}}
        mapContainerClassName="map-container"
      >
        {selected && <Marker position={selected} />}
      </GoogleMap>
      
    </>
  );
}
}
export default Home

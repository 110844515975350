import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Footer from './footerNew';
import Logo from '../assets/Rate Rental Logo-7.png';
import { Helmet } from 'react-helmet-async';
import '../css/styles.css'
import '../css/custom2.css'

function ContactUs () {
const [name,setName] = useState("")
const [email,setEmail] = useState("")
const [message,setMessage] = useState("")
const [subject,setSubject] = useState("")



const PostData = ()=>{

    let config = {
        headers: {
          'Content-Type': "application/json",
          

        }
      }

      let userData = {

        email: email,
        name: name,
        message:message,
        subject:subject


      }
axios.post( "http://raterental.co.uk/user/email", userData, config)
.then(res=> {
    console.log(res)
})
.then(data=>{
alert(data.message)
setMessage('')
setName('')
setSubject('')
setEmail('')
}).catch(err=>{
console.log(err)
})
console.log(userData)
}
return (
<>
      <Helmet>
        <title>Contact Us</title>
        <meta name="description" content="If you have any queries contact us at info@raterental.co.uk" />
        <link rel="canonical" href="/contact-us"/>
      </Helmet>

<div className='wrapper'>
<div className='form'>
    <div className='signup'>
        <h2>Contact Us</h2>
    </div>


<form onSubmit={e => this.handleSubmit(e)} noValidate>
<div className='line'>
    <label for="input"></label>
        <div className="input">
            <input
                type="text"
                placeholder="email"
                value={email}
                size='30'
                onChange={(e)=>setEmail(e.target.value)}
/>
        </div>
</div>

<div className='line'>
    <label for="input"></label>
        <div className="input"></div>
            <input
            type="text"
            placeholder="name"
            value={name}
            size='30'
            onChange={(e)=>setName(e.target.value)}
/>
</div>


<div className='line'>
    <label for="input"></label>
        <div className="input">
            <input
            type="text"
            placeholder="subject"
            size='30'
            value={subject}
onChange={(e)=>setSubject(e.target.value)}
/>
</div>
</div>

<div className='line'>
<label for="input"></label>
</div>

<div className='textarea-flex'>
<textarea

type="text"
className='message'
placeholder="Message"
width='30'
value={message}
onChange={(e)=>setMessage(e.target.value)}
/>
<br/>
<button className="btn-primary form-control"
onClick={()=>PostData()}
>
Send Message
</button >
</div>

</form>
</div>
<section className='foot-container'>
     <div className="foot">
        <Footer/>
      </div>
     </section>
      
</div>
</>)
}

export default ContactUs
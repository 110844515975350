import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import GoogleSignup from './signupGoogle';
import Logo from '../assets/Rate Rental Logo-7.png';
import Footer from './footerNew';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CookieConsent, { Cookies } from "react-cookie-consent";
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
 

const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const formValid = ({ formErrors, username, email, password }) => {
  let valid = true;

  // validate form errors being empty
  Object.values(formErrors).forEach(val => {
    val.length > 0 && (valid = false);
  });

  // validate the form was filled out
  Object.values({ username, email, password }).forEach(val => {
    val === null && (valid = false);
  });

  return valid;
};

class LoginValidate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: "",
      username: "",
      email: "",
      password: "",
      redirect:"",
      formErrors: {
        username: "",
        email: "",
        password: ""
      }
    };

    this.change = this.change.bind(this);
    this.submit = this.submit.bind(this);
  }

  change = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = { ...this.state.formErrors };

    switch (name) {
      case "username":
        formErrors.username =
          value.length < 3 ? "minimum 3 characaters required" : "";
        break;
      case "email":
        formErrors.email = emailRegex.test(value)
          ? ""
          : "invalid email address";
        break;
      case "password":
        formErrors.password =
          value.length < 6 ? "minimum 6 characaters required" : "";
        break;
      default:
        break;
    }

    this.setState({ formErrors, [name]: value }, () => console.log(this.state));
    this.setState({
      [e.target.name]: e.target.value
    });
  };


  changeShow() {
    if (localStorage.getItem('user')) {
    this.setState({
      redirect: true
      })
    }}

    handleSubmit = (e) => {
      e.preventDefault();


      if (formValid(this.state)) {
  this.submit();
        console.log(`
          --SUBMITTING--
          username: ${this.state.username}
          Email: ${this.state.email}
          Password: ${this.state.password}
        `);
      } else {
        console.error("FORM INVALID - DISPLAY ERROR MESSAGE");
      }
    };

    showToastMessageEmailSent = (message) =>{
      const toastId = 'fetched-nationalities';
      toast.warning(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000, //3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        toastId,
        transition: Slide
      });
      console.log('This is the show toast func')
      console.log(message)
    };

  submit = () => {
    //e.preventDefault();

    axios.post(process.env.REACT_APP_API_ADDRESS + `/user/login`, {
      username: this.state.username,
      email: this.state.email,
      password: this.state.password
    }).then(res => {
      console.log('%c original JWT Response','color:red; font-weight:bold;')
      console.log(res)
      const dataToo = res.data.msg
      this.showToastMessageEmailSent(dataToo)
      if (!res.data.token && !res.data.username) {
        this.props.history.push('/login');

      } else {
      localStorage.setItem('user', res.data.token);
      localStorage.setItem('username', res.data.username);
      this.setState({
        userId: res.data.userId
      })
      console.log(res.data.token);
      console.log(res.data.userId);
      this.changeShow();
      console.log(res.data);
    }}
    )

     .catch(err => {
      localStorage.removeItem('user');
      this.props.history.push('/login');
      this.setState({
        redirect: "false"
      })
    });
    //this.changeShow();
  }

  renderRedirect = () => {
if (this.state.redirect) {
 return <Redirect to='/post-review' />
}
}

  render() {
    const { formErrors } = this.state;
    if (this.state.redirect) {
      return <Redirect to='/post-review' />}
    return (
      <>
        <Helmet>
        <title>Login</title>
        <meta name="description" content="Login to your account and rate your experience as a tenant in a rented property" />
        <link rel="canonical" href="/login"/>
      </Helmet>
      <div className="wrapper">
        <div className='signup'>
        <h2>Log in With Google</h2>
        
        <GoogleSignup

          scope="openid profile email"/>
        </div>
            <div className='signup-or'>
          <h2><span>or</span></h2>
        </div>
        <div className='signup-email'>
          <h4>Log in With Email</h4>
        </div>
        <div className="form-wrapper">
        <form className='foot-form' onSubmit={e => this.handleSubmit(e)} noValidate>
          <div className="line">
          <label htmlFor="input"></label>
          <div className="input">
          <input
          className={formErrors.username.length > 0 ? "error" : null}
          placeholder="username"
          type="text"
          name="username"
          size="30"
          noValidate
          onChange={e => this.change(e)} value={this.state.username}
          />
          {formErrors.username.length > 0 && (
            <span className="errorMessage">{formErrors.username}</span>
          )}
        </div>
        </div>

        <div className="line">
          <label htmlFor='input'></label>
          <div className="input">
          <input
          className={formErrors.email.length > 0 ? "error" : null}
          placeholder="Email"
          type="email"
          name="email"
          onChange={e => this.change(e)}
          value={this.state.email}
          noValidate
          size="30"
          />
          {formErrors.email.length > 0 && (
            <span className="errorMessage">{formErrors.email}</span>
          )}
        </div>
        </div>
        <div className="line">

          <label htmlFor="input"></label>
          <div className="input">
          <input
          className={formErrors.password.length > 0 ? "error" : null}
          placeholder="Password"
          type="password"
          name="password"
          noValidate
          size="30"
          onChange={e => this.change(e)}
          value={this.state.password}
          />
          {formErrors.password.length > 0 && (
            <span className="errorMessage">{formErrors.password}</span>
          )}
          </div>
          </div>
          <div className="button-center-2">
            <div>
          <button className='btn btn-primary form-control' type="submit" onClick={() => {this.renderRedirect()}}>Login</button>
            </div>
          </div>
          <div className='button-text'>
          <Link to='/signup'>Create An Account</Link>
          </div>
        </form>
        </div>
        <section className='foot-container'>
      <div><ToastContainer/></div>
     <div className="foot">
      <Footer/>
      </div>
     </section>
        <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
            
        </div>

   </> )
  }
}
export default LoginValidate;

import React from 'react'
import { useState, useEffect, useRef } from "react";
import {useLocation, withRouter} from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import AddressCardFinalAdd from './addressCardFinalAdd'
import StarRating from './starRating'
import Logo from '../assets/Rate Rental Logo-7.png';
import Home from './googleMaps'
import PlacesAutocomplete from './googleMaps'
import { Card, Row, Col, Container } from "react-bootstrap";
import Mode from './addressCardPreviewModal'
import Footer from './footerNew';
import {FaStar} from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faHouse, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from 'react-helmet-async';

import '../css/styles.css'
import { render } from '@testing-library/react';


const AddressCardPreview = ({props, history}) => {

  const [splitAddress, setSplitAddress] = useState ([]);
  const [addressId, setAddressId] = useState([]);
  const [mergedComms, setMergedComms] = useState([]);
  const [grpUsrItems,setGrpUsrItems] = useState([]);
  const [usrImages, setUsrImages] = useState([]);
  const [usrImagesOnly, setUsrImagesOnly] = useState([]);
  const [userId, setUserId] = useState([]);
  const [showUser, setShowUser] = useState(false);
  const [png, setPng] = useState ([]);

  const {state} = useLocation()
  
  const [apisResponse, setApisResponse] = useState({});
  const [theAddress, setAddress] = useState([]);
  const [groupRef, setGroupRef] = useState([]);
  const [grpRefMp4, setGrpRefMp4] = useState([]);

//  const {history} = props.history
  console.log('%c API Response','color:Red; font-weight:bold;')
  console.log(apisResponse)
//Main API Data Variable
  // const address = JSON.parse(JSON.stringify(apisResponse))
  const address = apisResponse
  console.log('%c Address Props','color:red; font-weight:bold;')
  console.log(address)
  const comments = address.comments
console.log('%c Comments','color:green; font-weight:bold;')
console.log(comments)

const recommendations = address.recommendations
console.log('%c Recommendations','color:green; font-weight:bold;')
console.log(recommendations)

const ratingObj = address.ratingObj
console.log('%c Rating Object','color:green; font-weight:bold;')
console.log(ratingObj)

const rating = address.ratings
console.log('%c Rating Integers','color:green; font-weight:bold;')
console.log(rating)

const images = address.images
console.log('%c Images','color:green; font-weight:bold;')
console.log(images)

//Postcode placed in variable and space between code removed
const code = address.postcode
console.log('%c Postcode','color:Red; font-weight:bold;')
console.log(code)

let code2 = []

// let codeRmSpace = []

const postCodeSpace = (rmSpace) => {
  if (code && code.length > 0)

  code2 = code.split(" ").join('')

  }

  const joinAtSpce = postCodeSpace(code)
  console.log('%c Postcode Google Maps','color:Red; font-weight:bold;')
  console.log(code2)
 


const url = `http://maps.google.com/maps/api/staticmap?center=${code2}&zoom=18&size=320x240&maptype=roadmap&markers=scale:2|color:ORANGE|label:A|${code2}&sensor=false&key=AIzaSyB2EY3MAIcanoimPDLJbk7i4JWve7VebzA`

  useEffect(() => {
       console.log("Results from results page: ");
       console.log(state);

       const apiResponseCopy = state.response
       const responses =apiResponseCopy.data.data[0]
       console.log('%c Postcode API Response Variable','color:green; font-weight:bold;')
       console.log(responses);
       setApisResponse(responses);
       setAddress(responses)
       
       const apResponse = responses
    
     }, [state])

    useEffect(()=> {
       //Arrays to push flattened nested array of objects to Imagess Objects.
  //To make it easier to match against user array.
  const flattened = []

  //Flattened2 is array with object {img:url} originating from
  //Flattened1 Array
    const flattened2 = []
    const flattened3 = []

const flatten = (array) => {
if (array && array.length > 0) {

return flattened.push(array.reduce((results, item) => [...results, ...item.imagess.map(img => ({ img: img }))], []));
}}

flatten(images);

console.log('%c Flattened','color: red;')
console.log(flattened); 

//Important Creates Array Of Objects
flattened.map(x => console.log(x.map((itm, m) => flattened2.push(itm))))
console.log('%c Flattened2 Object Values','color: orange;')
console.log(Object.values(flattened2));

//Maps object value removing name prefix leaving URL String Only and Pushes String To Flattened3 Variable
const urls = [...new Set(flattened2.map(value => value["img"]))];
console.log('%c URLs Variable','color: Green;')
console.log(urls);

flattened3.push(urls)

console.log('%c Flattend3 URL Values','color: Green;')
console.log(flattened3);

//Check User Image Responses For Files That Have ext .mp4 And Push To
// awsCheck array

const awsCheckArr = []

const mp4Check = (flat) => {
if (url && url.length >0) {
for (let i=0; i <urls.length; i++  ) {
  if (urls[i].toString().endsWith(".mp4"))
    awsCheckArr.push(urls[i])}

  console.log('%c Check URLs With ext.Mp4','color: Red;')
  console.log(awsCheckArr);
 }
}   
mp4Check(flattened3)

// Cut .mp4 from str and append .png as extension to string

const mpToPngArr = []

const  mtpArr = (arr) => {
  if (arr.length > 0 ) {
    for (let i=0; i<arr.length; ++i) {
     const img = images
      if (arr[i].endsWith(".mp4"))
        mpToPngArr.push(arr[i].replace('.mp4','/thumb00001.png'))
        // images[i].imagess.push(arr[i].replace('.mp4','/thumb00001.png'))
        // Object.values(img).push(arr[i].replace('.mp4','/thumb00001.png'))
        img.map((img, cur) => {
        //If check appends and pushes png url then stops loop from pushing it again on next iteration as a png file is present.
          if (img.imagess.toString().includes('.mp4' ) && !img.imagess.toString().includes('.png' ) ) {
        img.imagess.push(img.imagess[i].replace('.mp4','/thumb00001.png'))}})

        console.log('%c Amended URLs With ext.png','color: Orange; font-weight:bold;')
        console.log(images);
    }
}}

mtpArr(awsCheckArr)

//Add S3 Bucket URL for .mp4 to thumbnail image to an empty array
//Use array to check against user images to find match in string
const usrThumbChk = []

//Check S3 Bucket for .mp4 png thumbnail. If it png exists push to Array

const  mtpArr2 = (arr) => {
  if (arr.length > 0 ) {
    for (let i=0; i<arr.length; ++i) {
  fetch (`${arr[i]}`)
    .then((response )=> {
      usrThumbChk.push(response.url)
      console.log(response);
      console.log(usrThumbChk);
    })
    // .then(()=>{

    //   let mergy = [];

    //   for(let i=0; i<images.length; i++) {
    //     mergy.push({
    //      // ...(usrThumbChk.find((itmInner) => itmInner.author === comments[i].author)),
    //      ...(images.find((itmInner) => itmInner.imagess.includes(usrThumbChk[i])))}
    //     );
    //   }
    //   console.log(mergy);
    // })
    .catch((error)=> {
      console.log(error.response);
    })}
}}

mtpArr2(mpToPngArr);

let mergedGrp = [];

if (comments && comments.length>0) {
for(let i=0; i<comments.length; i++) {
  mergedGrp.push({
   ...comments[i],
  // ...(comments.find((itmInner) => itmInner.author === comments[i].author)),
   ...(recommendations.find((itmInner) => itmInner.author === comments[i].author)),
   ...(rating.find((itmInner) => itmInner.author === comments[i].author)),
   ...(ratingObj.find((itmInner) => itmInner.author === comments[i].author)),
   ...(images.find((itmInner) => itmInner.author === comments[i].author))
    });
  }
}

setGroupRef(mergedGrp)


console.log('%c Merged Group','color:red; font-weight:bold;')
console.log(mergedGrp)


    },[apisResponse])



 

//********************************************************** */

//   let mergedGrp = [];

// if (comments && comments.length>0) {
// for(let i=0; i<comments.length; i++) {
//   mergedGrp.push({
//    ...comments[i],
//   // ...(comments.find((itmInner) => itmInner.author === comments[i].author)),
//    ...(recommendations.find((itmInner) => itmInner.author === comments[i].author)),
//    ...(rating.find((itmInner) => itmInner.author === comments[i].author)),
//    ...(ratingObj.find((itmInner) => itmInner.author === comments[i].author)),
//    ...(images.find((itmInner) => itmInner.author === comments[i].author))
//     });
//   }
// }

// console.log('%c Merged Group','color:red; font-weight:bold;')
// console.log(mergedGrp)


//*************************************************************** */


//************************************************************ */
// Main API Data Variable
  // const address = JSON.parse(JSON.stringify(apisResponse))
  // const address = apisResponse
  // console.log('%c Address Props','color:red; font-weight:bold;')
  // console.log(address)
  //********************************************************** */


// User Data Split into Comment Ratings Recommendations



//***************************************************************** */

// let mergedGrp = [];

// if (comments && comments.length>0) {
// for(let i=0; i<comments.length; i++) {
//   mergedGrp.push({
//    ...comments[i],
//   // ...(comments.find((itmInner) => itmInner.author === comments[i].author)),
//    ...(recommendations.find((itmInner) => itmInner.author === comments[i].author)),
//    ...(rating.find((itmInner) => itmInner.author === comments[i].author)),
//    ...(ratingObj.find((itmInner) => itmInner.author === comments[i].author)),
//    ...(images.find((itmInner) => itmInner.author === comments[i].author))
//     });
//   }
// }

// console.log('%c Merged Group','color:red; font-weight:bold;')
// console.log(mergedGrp)


// //Create new array of User Comments, Recommendations etc using concat method
// let merged = [];

// // const checkMp4Args = checkMp4 (images)

// console.log(merged)
// let mergy = []

// const mergedUsrs = (toConcat) => {
//  if (comments && comments.length > 0)

//   mergy = comments.concat (recommendations,ratingObj,images)
// console.log('%c Concat array of grouped User Items','color: blue;')

// }

//************************************************************************ */




    //******Working Function below************************//

    //   const pngFetch = (arr) => {
    //     if (arr.length > 0 ) {
    //       for (let {imagess = 'url'} of arr)
    //   {
        
    //     console.log('%c PNG FETCH','color: red;')
    //     console.log(imagess);
    //     for(let i=0; i <imagess.length; i++) {
            
    //         if (imagess[i].toString().includes('.mp4')) {
    //           console.log('%c MP4','color: blue;')
    //           console.log(imagess.toString().includes('.mp4'))
              
    //         imagess.push(
    //           imagess.toString().replace('.mp4','/thumb00001.png')
    //           )

    //           console.log('%c PNG ADD','color: green;')
    //           console.log(imagess);
    //       }


    //         console.log(imagess)
    //         fetch (`${imagess[i]}`)
    //         .then((response)=>{
    //           console.log(response)
    //         })
    //         }
    //   }
    //   }
    // }

    //   pngFetch(mergedGrp)

    //******Working Function above**********************//



//******************************************************* */
  const clickHappened = (id) => () => {
    console.log('%c Click Happened','color: blue')
    console.log(id)
  setUserId(id)
    setShowUser(true)
}

  const clickHappens = (id) => () => {
  setUserId(prevState => ({
    clicked: prevState.clicked === id ? null : id, // <-- toggle back to null or to new id
  }));
}



function handleClick (userIndex) {
  let userIdex = userIndex
  history.push({
    pathname: "/addressCardFinalAdd",
    state: {
      comms: userIdex,
      users: groupRef,
      address: address
    }}
    )}
//*************************************************************** */



// const fileExtensions = ['.png', '.jpeg', '.jpg'];
// const googleUrl = url

// const grpRefMap = groupRef.map((result, current) => result)

// console.log('%c Grp Ref Map','color: blue;font-weight:bold;')
// console.log(grpRefMap)

// const preFilteredImages = grpRefMap.map((images, current) => images.imagess )
 
// const filteredImages = preFilteredImages.map((imgs, cur) => imgs.filter((img) => {
//   const extension = img.substring(img.lastIndexOf('.'));
//   console.log(extension)
//   return fileExtensions.includes(extension) && extension !== '.mp4';
  
// }));

// let imageSource = googleUrl;
// if (filteredImages.length > 0) {
//   imageSource = filteredImages[0];
// }

// console.log('%c Filtered Images','color: blue;font-weight:bold;')
// console.log(filteredImages)





return (
<>
 <Helmet>
 <title>Tenant Reviews</title>
        <meta name="description" content="Tenant Review Search Results" />
        <link rel="canonical" href="/user-review-preview"/>
 </Helmet>
    

  <div>


<section className='cards'>
<div className='card-container'>
<div className='card-address cardie-2'>
<div className='cardie-home-icon'><FontAwesomeIcon icon={faHouse} size='xl' /></div>
  <h2>{address.line1}</h2>
  <h2>{address.town}</h2>
  <h2>{address.postcode}</h2>
</div>
  { !showUser ? <div>

      <StarRating rate={rating}/>
      <div className='google-map-btn'>
        <Mode pCode = {code2}/>

      </div>


<Container>
        <Row>


            {groupRef.map((e,k) =>  (
                <Col key={k} xs={12} md={4} lg={2}>
                    <Card>
                      {/* {e.imagess.find((img, cur) => {
                        // const fileExtensions = ['.png', '.jpeg', '.jpg'];
                        // const googleUrl = url
                        // const extension = img.toString().substring(img.lastIndexOf('.'));
                        // let imageSource = url;
                        // let imageSource2 = fileExtensions.includes(extension) && extension !== '.mp4'
                        // return (<Card.Img key={cur}height={150} width={75} src={img}/>) 
                        //  : null
                        // (<Card.Img key={cur}height={150} width={75} src={imageSource}/>) 
                          }
                         
                     )} */}


                      {e.imagess.filter((img, current) => !img.includes('.mp4')).map((filtImg, cur )=> {
                         return filtImg.includes('.mp4') ? null 
                        :
                          filtImg.substring(filtImg.lastIndexOf('.')).includes('.png') || ('.jpg') || ('.jpeg') ? (<Card.Img key={cur}height={150} width={75} src={filtImg}/>) 
                            : 
                              null
                          })
                        } 

                        {e.imagess.length === 0 ? (<Card.Img height={150} width={75} src={url}/>) 
                          :
                            null
                          
                        }

                     {/*  {e.imagess.filter((img, current) => !img.includes('.mp4')).map((filtImg, cur )=> {
                         return filtImg.includes('.mp4') ? null 
                        :
                          filtImg.substring(filtImg.lastIndexOf('.')).includes('.png') ? (<Card.Img key={cur}height={150} width={75} src={filtImg}/>) 
                            : 
                              filtImg.substring(filtImg.lastIndexOf('.')).includes('.jpg') ? (<Card.Img key={cur}height={150} width={75} src={filtImg}/>) 
                              :
                                filtImg.substring(filtImg.lastIndexOf('.')).includes('.jpeg') ? (<Card.Img key={cur}height={150} width={75} src={filtImg}/>) 
                                  :
                                  filtImg.length === 0 ? (<Card.Img key={cur}height={150} width={75} src={url}/>) 
                                    : null
                          })
                        } */}

                     {/* {e.imagess.filter((img, current) => !img.includes('.mp4')).map((filtImg, cur )=> {
                         return filtImg.includes('.mp4') ? null 
                        :
                         e.imagess.filter((img, current) => img.includes(".png")).map((filtImg, cur )=> {
                          return filtImg.substring(filtImg.lastIndexOf('.')).includes('.png') || ('.jpg') || ('.jpeg') ? (<Card.Img key={cur}height={150} width={75} src={filtImg}/>) 
                          : 
                          e.imagess.filter((img, current) => img.includes(".png")).map((filtImg, cur )=> {
                            return filtImg.length === 0 ? (<Card.Img key={cur}height={150} width={75} src={url}/>) 
                            : null
                          })
                        })
                      })
                    } */}

                      

                    {/* {e.imagess.filter((img, current) => !img.includes('.mp4')).map((filtImg, cur )=> {
                         return filtImg.includes('.mp4') ? null 
                        :
                         e.imagess.filter((img, current) => img.includes(".png")).map((filtImg, cur )=> {
                          return filtImg.includes('.png') && cur === 0 ? (<Card.Img key={cur}height={150} width={75} src={filtImg}/>) 
                          : null
                        })
                      })
                    } */}

                      {/* {e.imagess.filter((img, current) => !img.includes('.mp4')).map((filtImg, cur )=> {
                         return filtImg.includes('.mp4') ? null 
                        :
                         e.imagess.filter((img, current) => img.includes(".jpg")).map((filtImg, cur )=> {
                          return filtImg.includes('.jpg') && cur === 0 ? (<Card.Img key={cur}height={150} width={75} src={filtImg}/>) 
                          : null
                        })
                      })
                    }

                      {e.imagess.filter((img, current) => !img.includes('.mp4')).map((filtImg, cur )=> {
                         return filtImg.includes('.mp4') ? null 
                        :
                         e.imagess.filter((img, current) => img.includes(".jpeg")).map((filtImg, cur )=> {
                          return filtImg.includes('.jpeg') && cur === 0 ? (<Card.Img key={cur}height={150} width={75} src={filtImg}/>) 
                          : null
                        })
                      })
                    }   

                    //   {e.imagess.map((filtImg, cur )=> {
                    //       return filtImg.length === 0 ? (<Card.Img key={cur}height={150} width={75} src={url}/>) 
                    //       : null
                        
                    //   })
                    // }  */}

                        <Card.Body>
                            <Card.Title><span>{<FontAwesomeIcon icon={faUser} size="xs" />}</span> {e.username}<br/>
                            
                            {[...Array(5)].map((star, index) => {

                                const ratingValue = index + 1


                                  return (
                                    <label key ={index}>
                                      <input
                                        type ="hidden"
                                        name = "rating"
                                        value = {e.rating}
                                        />

                                      <FaStar key ={index} color={ratingValue <= e.rating ? "#ffc107" : "#e4e5e9"} size={15} />
                                    </label>
                                  )
                                  })
                                  } </Card.Title>



                            <Card.Text>Recommend:  {e.recommend}</Card.Text>



                            <div>

                              <button className="btn btn-primary form-control" key={k} onClick={() => {handleClick(k); clickHappened(k)}}>
                                View User Review
                              </button>
                              </div>

                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
    </Container>

  </div> : null }
</div>
</section>
<section>
  <div> { showUser ?
<div>

{/* <AddressCardFinalAdd
comms = {userId}
users = {mergedGrp}
  /> */}

{console.log(address)};
</div> : null}

</div>
</section>
<section className="foot-container">

  <div>
    <Footer/>
  </div>
</section>
</div>

</>)

};



// AddressCardPreview.propTypes =                  {
//   add: PropTypes.object.isRequired,
//   rating: PropTypes.arrayOf(PropTypes.number.isRequired),
//
//   address: PropTypes.arrayOf(PropTypes.shape({
//     _id: PropTypes.string.isRequired,
//     comments:PropTypes.arrayOf(PropTypes.object.isRequired),
//     ratings:PropTypes.arrayOf(PropTypes.number.isRequired)
//   }))
//   };


export default withRouter(AddressCardPreview)



import React from "react";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet-async";



function  Tenants () {

    return (
        <>
        <Helmet>
        <title>Tenant Terms And Conditions</title>
        <meta name="description" content=" Terms and conditons for users of the site that are tenants and are writing reviews" />
        <link rel="canonical" href="/tenant-tcs"/>
        </Helmet>

        <div className="policy-row">
			<div className="small-12 columns"></div>
            <div className="small-12 columns">
				<p>This page (together with our privacy policy <Link to="/privacy">https://raterental.co.uk/privacy-policy/</Link>, website terms of use <Link to="/tc's">terms of use</Link> and acceptable use policy <Link to="/acceptable-use"></Link> tells you information about us and the legal terms and conditions (Terms) on which we supply the service described on our website (our site) to you https://raterental.co.uk/ (<strong>the Service</strong>).</p>
<p>These Terms will apply to any contract between us for the supply of the Service to you (Contract). &nbsp;Please read these Terms carefully and make sure that you understand them, before registering on our site.&nbsp; Please note that before registering you will be asked to agree to these Terms. &nbsp;If you refuse to accept these Terms, you will not be able to register on our site or use the Service.</p>
<p>You should print a copy of these Terms or save them to your computer for future reference.</p>
<p>We may amend these Terms from time to time as set out in clause 6.</p>
<p>These Terms, and any Contract between us, are only in the English language.</p>
<p>1.;Information about us and how to contact us</p>
<p>1.1; We operate the website <Link to="/home">www.raterental.co.uk</Link>. &nbsp;We are Ask Tenants Limited, a limited company registered in England and Wales under company number 09238027 and with our registered office and main trading address at 20-22 Wenlock Road London N1 7GU United Kingdom</p>
<p>1.2; If you have any questions or if you have any complaints, please contact us by emailing us at <a href="mailto:info@raterental.co.uk">info@raterental.co.uk</a>.</p>
<p>1.3; If you wish to contact us in writing, or if any clause in these Terms requires you to give us notice in writing (for example, to cancel the Contract once you have been given access to the Service), you can send this to us by email to <a href="mailto:info@raterental.co.uk">info@raterental.co.uk</a>, by hand, or by pre-paid post to Rate Rental at 2 Tarbert Mews, London N15 5AB.&nbsp; If we have to contact you or give you notice in writing, we will do so by email, by hand or by pre-paid post to the address you provide to us when you register on our site.</p>
<p>1.4; When we refer, in these Terms, to “in writing” or “written”, this will include email.</p>
<p>2.;The Service and registration</p>
<p>2.1; We will supply the Service to you from the date on which we send you the Registration Confirmation as described in clause 5.2 below.</p>
<p>2.2; We do not accept Service users from outside the UK.</p>
<p>2.3; By registering on our site as a tenant, you confirm that:</p>
<p>(a); you are legally capable of entering into binding contracts;</p>
<p>(b); you are at least 18 years old;</p>
<p>(c); you are resident in the UK;</p>
<p>(d); you are accessing our site from that location.</p>
<p>2.4; <strong>Our registration process allows you to check and amend any errors before submitting your details to us, and we ask you to take the time to read and check your details at each stage of the registration process, and to ensure that you read these Terms carefully</strong>.&nbsp; You agree:</p>
<p>(a); to provide true, accurate, current and complete information about yourself as prompted by the Service registration process (<strong>Registration Details</strong>);</p>
<p>(b); that, in providing such Registration Details, you will not knowingly omit or misrepresent any material facts or information;</p>
<p>(c); that you will promptly enter corrected or updated Registration Details via the Service, or otherwise advise us promptly in writing of any such changes or updates; and</p>
<p>(d); to update your Registration Details as soon as they change.</p>
<p>2.5; When you submit your Registration Details to us, we will send you an email confirming receipt of your Registration Details, but this does not require us to accept your registration for the Service (see clause 5 below).</p>
<p>2.6; When you register to use the Service, you will need to enter your email address and password to access your account (<strong>User ID</strong>).&nbsp; You agree that you will not allow another person to use your User ID/email address to access or use the Service under any circumstances.&nbsp; You are fully responsible for entering your email address correctly. <strong>You under no circumstance will add an email address that is not your own</strong>. The website takes no ownership of you entering incorrect email address knowingly or unknowingly. You agree that you are solely and entirely responsible for your User ID/email address and for any charges, damages, liabilities or losses incurred or suffered as a result of your failure to keep it secure or entering an incorrect email address or any other registration details incorrectly.&nbsp; We are not liable for any charges, damages, liabilities or losses caused by or related to the theft of your User ID, your disclosure of your User ID, or you allowing another person to access and use the Service using your User ID.</p>
<p>2.7; Your User ID must not include any of the following:</p>
<p>(a); spaces or tabs;</p>
<p>(b); obscene or profane words;</p>
<p>(c); email addresses or website addresses or representations of email addresses or website addresses;</p>
<p>(d); another user’s username;</p>
<p>(e); the @, &amp;, ‘, (, ), &lt;, or &gt; symbols;</p>
<p>(f);&nbsp; consecutive underscores (__);</p>
<p>(g); an underscore (_), dash (-) or full stop (.) at the beginning of a username; or</p>
<p>(h); the words “Ask Tenants”.</p>
<p>2.8; You are solely and entirely responsible for any and all use of your account, including any charges incurred relating to the Service.&nbsp; You agree immediately to notify us of any unauthorised use of your account, or any other breach of security known to you.</p>
<p>2.9; You acknowledge that the complete privacy of your data and messages transmitted over the internet while using the Service cannot be guaranteed.</p>
<p>2.10; Your access to the Service may occasionally be restricted to allow for repairs, maintenance or the introduction of new facilities or services.&nbsp; We will attempt to restore the Service as soon as we reasonably can.</p>
<p>2.11; The Service does not include the provision of computer or other necessary equipment to access the Service.&nbsp; To use the Service you will require internet connectivity and appropriate telecommunication links.&nbsp; We will not be liable for any telephone or other costs that you may incur.</p>
<p>2.12; Registration for the Service is conducted in the English language only.</p>
<p>3.;Use of our site</p>
<p>Your use of our site is governed by our website <Link to="/tc's">terms of use</Link>, <Link to="/acceptable-use">acceptable use policy</Link> and our privacy policy Please take the time to read these, as they include important terms which apply to you.</p>
<p>4.;How we use your personal information</p>
<p>We only use your personal information in accordance with our privacy policy <Link to="/privacy">privacy policy</Link> Please take the time to read our privacy policy, as it includes important terms which apply to you.</p>
<p>5.;How the contract is formed between you and us</p>
<p>5.1 You will Register on our site using the Register button (https:/raterental.co.uk/signup). You will need to enter your email address, the property you wish to rate, tenancy dates, and other details. You are fully responsible for entering the correct details during the registration process.</p>
<p>5.2 The Contract between us will only be formed when you accept our terms and conditions and click “Create Account and Rate” button on our registration page.&nbsp; If you are a consumer, the Contract constitutes a distance contract between us for the purposes of the Consumer Contracts (Information, Cancellation and Additional Charges) Regulations 2013 (the<strong> Regulations</strong>).<strong>&nbsp; By applying to register on our site, you are requesting us to make the Service available to you as soon as we send you the Registration Confirmation, and by doing so you accept that you will not be able to cancel the Contract during any cancellation period provided for under the Regulations.&nbsp; </strong>Your rights to cancel the Contract are set out in clause 8.</p>
<p>6.;Additional obligations for tenants</p>
<p>6.1; By registering to use the Service, you acknowledge and agree that:</p>
<p>(a); by giving us details, information, comments, reviews photographs and other images relating to the property you have rented or are renting (as the case may be) (<strong>the Property</strong>) and its landlord for publication on our site (<strong>the Information</strong>), you grant us a royalty-free, perpetual, irrevocable and non-exclusive right and licence to use, reproduce, distribute, display, modify and edit the Information in connection with the Services, and you warrant and represent that:</p>
<p>(i); you have all rights and authority necessary to grant us these rights (including, where applicable, any consents required under the General Data Protection Regulation and any associated laws regarding data privacy);</p>
<p>(ii); you are or have been a tenant at the Property;</p>
<p>(iii) you are a private individual acting in your capacity as such, and are not an estate agent or the landlord of the Property, or a third party acting on behalf of an estate agent or the landlord of the Property; and</p>
<p>(iv) the Property is a private dwelling or residence and is not a commercial, industrial, business, retail or agricultural property of any kind;</p>
<p>(b); any Information you provide will comply in all respects with the provisions of our acceptable use policy <Link to="/acceptable-use">https://raterental.co.uk/acceptable-use-policy/</Link>, including in particular but without limitation the content standards set out in that policy, and with our privacy policy <Link to="/privacy">https://raterental.co.uk/privacy-policy/</Link>;</p>
<p>(c); we retain the right at our sole discretion to remove from our site or edit the Information at any time;</p>
<p>(d); we are not an estate agency, and we merely provide an online service whereby tenants and prospective tenants may view information and reviews about rental properties, and the area in which those properties are situated;</p>
<p>(e); you are solely responsible for preparing the Information in good faith, and for ensuring that it is complete and accurate in all respects, that it complies with all applicable laws (including without limitation the requirements of the General Data Protection Regulation and any associated laws regarding data privacy), and with our acceptable use policy <Link to="/acceptable-use">https://raterental.co.uk/acceptable-use-policy/</Link> &nbsp;and that it is not misleading in any way (including but without limitation in relation to the state and condition of the Property or its amenities, and in relation to any comments about the landlord of the Property).</p>
<p>6.2; You further agree that you will indemnify and keep us indemnified against any and all actions, costs, claims, demands, damages, expenses (including legal fees), liabilities, losses and proceedings in connection with your breach of these Terms (including but without limitation any breach of the provisions of this clause 6), or any claim by a third party in connection with a Property (including, without limitation, in the event of any dissatisfaction on the part of a third party or the landlord of the Property regarding the Information you have supplied in relation to it for publication on our site).</p>
<p>7.;Our right to vary these Terms</p>
<p>7.1; We may amend these Terms from time to time in the event of any changes in how we accept payment from you, and changes in any relevant laws and regulatory requirements.</p>
<p>7.2; If we have to revise these Terms under clause 7.1, we will give you at least 30 calendar days’ written notice of the changes before they take effect.&nbsp; You can choose to cancel the Contract in accordance with clause 8.3(c).</p>
<p>8.;Canceling the Contract</p>
<p>8.1; Access to the Service is made available to you as soon as we send you the Registration Confirmation as described in clause 5.2 above.</p>
<p>8.2; You may cancel the Contract at any time by providing us with at least 30 calendar days’ notice in writing.</p>
<p>8.3; You may cancel the Contract with immediate effect by giving us written notice if:</p>
<p>(a); we break the Contract in any material way and we do not correct or fix the situation within 30 calendar days of you asking us to in writing;</p>
<p>(b); we go into liquidation or a receiver or an administrator is appointed over our assets;</p>
<p>(c); we change these Terms under clause 7.1 to your material disadvantage;</p>
<p>(d); we are affected by an Event Outside Our Control as defined in clause 12.</p>
<p>8.4; We may cancel the Contract at any time by providing you with at least 30 calendar days’ notice in writing.</p>
<p>8.5; We may cancel the Contract with immediate effect by giving you written notice if:</p>
<p>(a); you break the Contract in any material way and you do not correct or fix the situation within seven calendar days of us asking you to in writing; or</p>
<p>(b); we are affected by an Event Outside Our Control as defined in clause 12.</p>
<p>8.6; We reserve the right to modify or discontinue the Service (or any part of it) without notice to you at any time.&nbsp; Where reasonably practicable, we will try to give you reasonable notice of any discontinuation of the Service.</p>
<p>9.;If there is a problem with the Service</p>
<p>9.1; In the unlikely event that there is a problem with the Service, please contact us and tell us as soon as possible, and give us a reasonable opportunity to fix any problem.&nbsp; We will use every effort to resolve the problem as soon as reasonably practicable.</p>
<p>9.2; As a consumer, you have legal rights if the Service is not carried out with reasonable skill and care.&nbsp; Advice about your legal rights is available from your local Citizens’ Advice Bureau or Trading Standards Office.&nbsp; Nothing in these Terms will affect these legal rights.</p>
<p>10.; Our status – IMPORTANT INFORMATION</p>
<p>10.1; We are not estate agents, and we merely provide an online service whereby tenants and prospective tenants may view information and reviews about rental properties, and the area in which those properties are situated.&nbsp; We are not a party to any negotiations in relation to, or rental of, any Property shown on our site, and we do not accept any responsibility or liability whatsoever (whether to you or to any third party) in relation to any such negotiations or contracts.</p>
<p>10.2; We may provide links on our site to the websites of other businesses, organisations, and public bodies, whether affiliated with us or not.&nbsp; We cannot give any undertaking that products or services you receive from third parties following a visit to our site, or from businesses to whose website we have provided a link on our site, will be of satisfactory quality.</p>
<p>11.; Our liability to you</p>
<p>11.1; <strong>We make no representations as to the validity or accuracy of any opinion, advice, information or statement given or made by a third party which is shown on our site.&nbsp; Any views expressed do not necessarily represent our views</strong><strong>.</strong><strong>&nbsp; We do not accept any liability to you or any third party in this respect, and you are solely responsible for checking the accuracy of relevant facts, information, suggestions and/or opinions given by third parties on our site before relying on them.&nbsp; You acknowledge that this is reasonable because we have no means of checking that individuals who leave reviews on our site are who they say they are, and we have no means of vetting any Property which is shown on our site, or the landlord of any such Property.</strong></p>
<p>11.2; If we fail to comply with these Terms, we are responsible for loss or damage you suffer that is a foreseeable result of our breach of these Terms or our negligence, but we are not responsible for any loss or damage that is not foreseeable. &nbsp;Loss or damage is foreseeable if it is an obvious consequence of our breach, or if it was contemplated by you and us at the time we entered into this contract.</p>
<p>11.3; We only supply the Service for domestic and private use. &nbsp;You agree not to use the Service for any commercial, business or resale purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</p>
<p>11.4; We do not in any way exclude or limit our liability for:</p>
<p>(a); death or personal injury caused by our negligence, or the negligence of our employees, agents or subcontractors;</p>
<p>(b); fraud or fraudulent misrepresentation;</p>
<p>(c); breach of the terms implied by section 2 of the Supply of Goods and Services Act 1982 (title and quiet possession);</p>
<p>(d); breach of the terms implied by sections 3, 4 and 5 of the Supply of Goods and Services Act 1982 (description, satisfactory quality, fitness for purpose and samples); and</p>
<p>(e); defective products under the Consumer Protection Act 1987.</p>
<p>12.; Events outside our control</p>
<p>12.1; We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under a Contract that is caused by an Event Outside Our Control. &nbsp;An Event Outside Our Control is defined below in clause 12.2.</p>
<p>12.2; An Event Outside Our Control means any act or event beyond our reasonable control, including without limitation strikes, lock-outs or other industrial action by third parties, civil commotion, riot, invasion, terrorist attack or threat of terrorist attack, war (whether declared or not) or threat or preparation for war, fire, explosion, storm, flood, earthquake, subsidence, epidemic or other natural disaster, or failure of public or private telecommunications networks.</p>
<p>12.3; If an Event Outside Our Control takes place that affects the performance of our obligations under these Terms:</p>
<p>(a); we will contact you as soon as reasonably possible to notify you; and</p>
<p>(b); our obligations under these Terms will be suspended and the time for performance of our obligations will be extended for the duration of the Event Outside Our Control. &nbsp;Where the Event Outside Our Control affects our provision of the Service, we will restart the Service as soon as reasonably possible after the Event Outside Our Control is over.</p>
<p>12.4; You may cancel the Contract if an Event Outside Our Control takes place and you no longer wish us to provide the Service to you.&nbsp; Please see your cancellation right under clause 8.3.&nbsp; We will only cancel the Contract if the Event Outside Our Control continues for longer than 24 weeks in accordance with our cancellation rights in clause 8.5.</p>
<p>13.; Other important terms</p>
<p>13.1; We may transfer our rights and obligations under a Contract to another organisation, but this will not affect your rights or our obligations under these Terms.&nbsp; We will always notify you in writing or by posting on this webpage if this happens.</p>
<p>13.2; You may only transfer your rights or your obligations under these Terms to another person if we agree in writing.</p>
<p>13.3; This Contract is between you and us. No other person shall have any rights to enforce any of its terms, whether under the Contracts (Rights of Third Parties) Act 1999 or otherwise.</p>
<p>13.4; Each of the paragraphs of these Terms operates separately. If any court or relevant authority decides that any of them are unlawful or unenforceable, the remaining paragraphs will remain in full force and effect.</p>
<p>13.5; If we fail to insist that you perform any of your obligations under these Terms, or if we do not enforce our rights against you, or if we delay in doing so, that will not mean that we have waived our rights against you and will not mean that you do not have to comply with those obligations. &nbsp;If we do waive a default by you, we will only do so in writing, and that will not mean that we will automatically waive any later default by you.</p>
<p>13.6delete; These Terms are governed by English law. This means a Contract for the provision of the Service to you through our site and any dispute or claim arising out of or in connection with it will be governed by English law. &nbsp;You and we both agree that the courts of England and Wales will have non-exclusive jurisdiction. &nbsp;However, if you are a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are a resident of Scotland, you may also bring proceedings in Scotland.</p>

			</div>



        </div>
   
		
	</>
    );
}

export default Tenants
import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { useState } from 'react'
import Logo from '../assets/Rate Rental Logo-7.png' 
import Privacy from './Policies/privacy'
import Terms from './Policies/terms'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../css/custom2.css'
import '../css/styles.css'





export default function Footer () {

const [email, setEmail] = useState({
})
const [formErrors, setFormErrors] = useState({
    email:""
})
const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );

const toastId = React.useRef(null);

const formValid = ({ email }) => {
    let valid = true;
   
    // validate form errors being empty
    // Object.values(formErrors).forEach(val => {
    //   val.length > 0 && (valid = false);
    // });
  
    // validate the form was filled out
    Object.values({ email }).forEach(val => {
      val === null && (valid = false);
    });
  
    return valid;
  };

const handleEmailChange = (e) => {
    e.preventDefault();
//     const nextEmailState = {
//         ...email,
//     [e.target.name]: e.target.value,
//     }
//     setEmail(nextEmailState)
//     let formErrors = { ...email.formErrors };
    const { name, value } = e.target;
    let formErrors = { ...formErrors };

    switch (name) {
        case "email":
          formErrors.email = emailRegex.test(value)
            ? ""
            : "invalid email address";
          break;
        default:
          break;
      }

      setFormErrors({[name]: value})

      if (formErrors.email == "") {
          setEmail(e.target.value)
          setFormErrors({email:""})
      }
    

  }

  const showToastEmailSubscribeError = () => {
    let delay = 1000
    toast.warning('Invalid email. Please use a valid email address', {
        position: toast.POSITION.BOTTOM_LEFT,
          toastId: 'Email Invalid',
          delay: delay
    });
  };

  const showToastSubscribedAlready = (res) => {
    let delay = 1000
    toast.warning(`${res.data.msg}`, {
        position: toast.POSITION.TOP_RIGHT,
          toastId: 'Subscribe Response',
          delay: delay
    });
  };

  const showToastSubscribe = (res) => {
    let delay = 1000
    toast.info(`${res.data.msg}`, {
        position: toast.POSITION.TOP_RIGHT,
          toastId: 'Subscribe Response',
          delay: delay
    });
  };

  const submit = (e) => {
    e.preventDefault();
    if (!formErrors.email.length > 0) {
        // const emails = Object.values(email)
        //     console.log(emails)
        let config = {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
  
            }
          }
        const emailData = {
            email:email
        }

        console.log(emailData)
    axios.post(`${process.env.REACT_APP_API_ADDRESS}/user/subscribe`, {
      emailData, config
    }).then(res => {
        console.log('%c Subscription Message Response','color:Blue; font-weight:bold;')
        console.log(res.data)
        if (res.data.msg === 'You are already subscribed to the newsletter') {
            showToastSubscribedAlready(res)
        } 

        if (res.data.msg === 'Subscription linked to your email. Confirmation email resent. Check your spam inbox.') {
            showToastSubscribedAlready(res)
        }
        
        if(res.data.msg === 'Email sent, please check your inbox to confirm') {
            showToastSubscribe(res)
        }
      console.log('%c Subscription Response','color:Green; font-weight:bold;')
      console.log(res)
     }
    ).catch(err => {
        console.log(err)
   });} else {
    showToastEmailSubscribeError()
   } 
  }


const theme = {
backgroundclass: "bg-color"
}

const footerNavs = [
    {
        label: "",
        items: [
            {
                to: '/tenant-tcs',
                name: 'Tenants'
            },
            {
                to: '/landlord-tcs',
                name: 'Landlords'
            },
            {
                to: '/agency-tcs',
                name: 'Agencies'
            }
        
        ],
    },
    {
        label: "",
        items: [
            {
                to: '/tcs',
                name: 'Terms'
            },

            {
                to: '/privacy',
                name: 'Privacy'
            },
            {
                to: '/cookies',
                name: 'Cookies'
            },
        ]
    },
    {
        label: "",
        items: [
            {
                to: '/report-abuse',
                name: 'Abuse'
            },
            {
                to: '/acceptable-use',
                name: 'Usage'
            },
            {
                to: '/contact-us',
                name: 'Contact'
            }
           
        ],
    }
]

return (
    <footer className="text-white bg-black px-[2%] py-5 max-w-screen-full mx-auto md:px-8">
        <div className="flex flex-col justify-center align-center gap-3 sm:flex">
            <div className="flex flex-col justify-center align-center">
                <div className="container width:50% flex m-auto justify-center align-center max-w-xs">
                <img className='object-contain'
                    src={Logo}
                    width={180} 
                    height={'10%'}
                    alt="Logo image"/>
                </div>
                <div className="container width:50% flex m-auto justify-center align-center max-w-xs" >
                    <p className="text-center leading-relaxed mt-2 text-[15px]">
                        Helping tenants move into rented properties with confidence.
                    </p>
                </div>
                
                <form className='subscribe pb-[2%]'>
                    <label className=" text-center block pt-4 pb-2">
                        Stay up to date
                    </label>
                    <div className="max-w-sm flex items-center border rounded-md p-1 text-black">
                        <input 
                            className='subscribe-input'
                            placeholder="Email"
                            type="email"
                            name="email"
                            onChange={e => handleEmailChange(e)}
                            // value={email.email}
                            noValidate
                            size="30"
                        />
                        <button onClick={e => {submit(e)}}
                            className="p-2.5 rounded-md btn btn-primary outline-none shadow-md focus:shadow-none sm:px-5"
                        >
                            Subscribe
                        </button>
                    </div>
                    <div>
                        {formErrors.email.length > 0 && (
                         <span className="errorMessage text-red-600">Invalid email address</span>
                        )}
                    </div>
                </form>
            </div>
            <div className="flex flex-col align-center justify-center text-center mt-10 space-y-2 items-center justify-between sm:flex sm:flex-row sm:flex-1 sm:text-center md:space-y-6 md:mt-0 lg:px-[20%] md:text-center">
                {
                    footerNavs.map((item, idx) => (
                        <ul
                            className="space-y-1 mb-0 px-[0%]"
                            key={idx}
                        >
                            <h4 className="text-gray-800 font-medium">
                                { item.label }
                            </h4>
                            {
                                item.items.map(((el, idx) => (
                                    <li key={idx}>
                                        <Link 
                                            to={el.to}
                                            className="hover:underline hover:text-indigo-600"
                                        
                                        >
                                            { el.name }
                                        </Link>
                                    </li>
                                )))
                            }
                        </ul>
                    ))
                }
            </div>
        </div>
        <div className=" flex flex-col justify-center align-center mt-8 px-[2%] py-6 border-t items-center justify-between sm:flex">
            <div className="mt-4 sm:mt-0 pb-[2%]">
                &copy; Rate Rental All rights reserved.
            </div>
            <div className="mt-6 sm:mt-0">
                <ul className="flex items-center px-[0%] space-x-4">
                    <li className="w-10 h-10 border rounded-full flex items-center justify-center">
                        <a href="https://twitter.com/Rate_Rental" aria-label="Twitter">
                            <svg className="svg-icon w-6 h-6 text-blue-400" viewBox="0 0 20 20">
                                <path fill="none" d="M18.258,3.266c-0.693,0.405-1.46,0.698-2.277,0.857c-0.653-0.686-1.586-1.115-2.618-1.115c-1.98,0-3.586,1.581-3.586,3.53c0,0.276,0.031,0.545,0.092,0.805C6.888,7.195,4.245,5.79,2.476,3.654C2.167,4.176,1.99,4.781,1.99,5.429c0,1.224,0.633,2.305,1.596,2.938C2.999,8.349,2.445,8.19,1.961,7.925C1.96,7.94,1.96,7.954,1.96,7.97c0,1.71,1.237,3.138,2.877,3.462c-0.301,0.08-0.617,0.123-0.945,0.123c-0.23,0-0.456-0.021-0.674-0.062c0.456,1.402,1.781,2.422,3.35,2.451c-1.228,0.947-2.773,1.512-4.454,1.512c-0.291,0-0.575-0.016-0.855-0.049c1.588,1,3.473,1.586,5.498,1.586c6.598,0,10.205-5.379,10.205-10.045c0-0.153-0.003-0.305-0.01-0.456c0.7-0.499,1.308-1.12,1.789-1.827c-0.644,0.28-1.334,0.469-2.06,0.555C17.422,4.782,17.99,4.091,18.258,3.266"></path>
                            </svg>
                        </a>
                    </li>

                    <li className="w-10 h-10 border rounded-full flex items-center justify-center">
                        <a href="https://www.facebook.com/profile.php?id=100089972060347" aria-label="Facebook">
                            <svg className="svg-icon w-6 h-6 text-blue-700" viewBox="0 0 20 20">
                                <path fill="none" d="M11.344,5.71c0-0.73,0.074-1.122,1.199-1.122h1.502V1.871h-2.404c-2.886,0-3.903,1.36-3.903,3.646v1.765h-1.8V10h1.8v8.128h3.601V10h2.403l0.32-2.718h-2.724L11.344,5.71z"></path>
                            </svg>
                        </a>
                    </li>

                    <li className="w-10 h-10 border rounded-full flex items-center justify-center">
                        <a href="https://www.instagram.com/rate_rentals" aria-label="Instagram">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                            </svg>
                        </a>
                    </li>

                    <li className="w-10 h-10 border rounded-full flex items-center justify-center">
                        <a href="https://www.linkedin.com/company/rate-rental/about" aria-label="LinkenIn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                        </svg>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div>
            <ToastContainer/>
        </div>
        <style jsx>{`
            .svg-icon path,
            .svg-icon polygon,
            .svg-icon rect {
                fill: currentColor;
            }
        `}</style>
    </footer>
)
}
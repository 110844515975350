import React from 'react';
import PropTypes from 'prop-types';
import AddressCardPreview from './addressCardPreview';
import { useHistory } from 'react-router-dom';


const SearchCardRed = ({OnRefClick }) => {

    return (

    <button className="btn btn-primary form-control" onClick={OnRefClick}>
      Search Property Here!
    </button>
)
};

// MongoCardPost.propTypes = {
//   post: PropTypes.array,
// };

export default SearchCardRed ;

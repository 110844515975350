import Card from 'react-bootstrap/Card';
import HomeSearch from '../assets/Search-Home-1.jpg'
import UKMap from '../assets/UK-Map-1.jpg'
import Rating from '../assets/AdobeStock_552648480-1.jpg'

import '../css/custom2.css'
import '../css/styles.css'

export default function HowTo () {
    return (
        <div>
         <div className= 'ml-[10%] mb-[5%] mt-[5%] md:ml-[20%] md:mr-[25%] lg:ml-[13%]'>
                <h2 className='mx-auto text-2xl/8 font-bold'>Search tenant property reviews.</h2> 
     
                <h3 className='mx-auto text-xl font-semibold'>Read user reviews. View images and video.</h3>
         </div>
    <div className='sub-grid-container'>
        <div className= "grid grid-cols-1 grid-flow-row gap-x-[2%] sm:grid-cols-1 sd:grid-flow-col sm:gap-y-[2%] lg:grid-cols-3 lg:gap-x-[2%]  ">
            <div className='how-to'>
                <Card style={{ width: '80%', height: '100%' }}>
                    <Card.Img variant="top" src={HomeSearch} style={{objectFit: 'cover', width: '100%', height: '30vh'}} alt="Image of a location, home and palmtree icon" />
                    <Card.Body className='bg-[##F8F0E3]'>
                        <Card.Title style={{fontSize: "16px", fontWeight: "Bold"}}>Rent with Confidence</Card.Title>
                            <Card.Text style={{fontSize: "14px"}}>
                            RateRental makes it easy for tenants to search for reviews of potential properties
                            you are considering moving into.
                            </Card.Text>
                    </Card.Body>
                </Card>
            </div>
            <div className='how-to'>
                <Card style={{ width: '80%', height: '100%' }}>
                    <Card.Img variant="top" src={UKMap} style={{ objectFit: 'cover', width: '100%', height: '30vh'}} alt="A UK Map using city names to create the map." />
                    <Card.Body className='bg-[##F8F0E3]'>
                        <Card.Title style={{fontSize: "16px", fontWeight: "Bold"}}>Search Reviews</Card.Title>
                            <Card.Text style={{fontSize: "14px"}}>
                            Enter the postcode in the search field and
                            Select the address from the menu and click the 'Search'
                            button.
                            </Card.Text>
                    </Card.Body>
                </Card>
            </div>
            <div className='how-to'>
                <Card style={{ width: '80%', height: '100%' }}>
                    <Card.Img variant="top" src={Rating} style={{objectFit: 'cover', width: '100%', height: '30vh'}} alt="A person holding an elctronic message in the palm of their hands" />
                    <Card.Body className='bg-[##F8F0E3]'>
                        <Card.Title style={{fontSize: "16px", fontWeight: "Bold"}}>User Reviews</Card.Title>
                            <Card.Text style={{fontSize: "14px"}} >
                            Preview user reviews. 
                            View the location on Google Maps.
                            Click 'View Comment' to read the complete user review.
                            </Card.Text>
                    </Card.Body>
                </Card>
            </div>

        </div>
      </div>
      </div>
    )
}
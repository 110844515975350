
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Logo from '../assets/Rate Rental Logo-7.png';
import Nav from './nav';
import Footer from "./footerNew";
import { Helmet } from "react-helmet-async";
// import { createHashHistory } from 'history'
const Logout = () => {

const [refreshPage, setRefreshPage] = useState(false)

useEffect (() => {
  const removeToken = () => { // export function from module
    localStorage.removeItem("user");
    localStorage.removeItem("username")
    console.log("Token removed");
    setRefreshPage(true)
   return <Nav navProp = "false"/>
  }

 

  removeToken()


},[]);



const history = useHistory()

// const refresh = () => {

// }
const location = useLocation()

const refresh = () => {
  window.location.reload()
  console.log('location2', location)
}
const loggedOut = () => {
  if (localStorage.getItem("user") === null) {
   //console.log("Redirected to login page");
  history.push({
  
    pathname: '/home'})
  }
  return <Nav navProp = "false"/>
  }
  
  return (
    <>
       <Helmet>
        <title>Login</title>
        <meta name="description" content="Logout of your account when you have completed a tenant review" />
        <link rel="canonical" href="/logout"/>
      </Helmet>
    <div>
      <div className="logout-success">
        <h2>You have successfully <br/>
              logged out</h2>
      </div>
      <div className="button-center">
        <button className="btn btn-primary form-control" onClick={() =>{loggedOut(); refresh();}}>
          Return To Homepage
        </button>
        </div>
      <div>
       
      </div>
      <section className='foot-container'>
     <div className="foot">
        <Footer/>
      </div>
     </section>
    </div>
  </>
  )
    
  
}


export default Logout;
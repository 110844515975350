import React from "react";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet-async";


function  Agency () {

    return (
        <>
        <Helmet>
        <title>Agency Terms and Conditions</title>
        <meta name="description" content="These Terms will apply to any contract between us and Agencies for the supply of Services to Agencies " />
        <link rel="canonical" href="/agency-tcs"/>
        </Helmet>

        <div className="policy-row">
			<div className="small-12 columns"></div>
            <div className="small-12 columns">
				<p>This page (together with the documents expressly referred to in it) tells you information about us and the legal terms and conditions (Terms) on which we supply the service (<strong>Service</strong>) referred to on our website at <Link to="/home">www.raterental.co.uk</Link> (our site) to you, the person, firm or company who registers to use the Service in your capacity as a landlord or the lawfully appointed agent of a landlord (<strong>you</strong>).</p>
<p>These Terms will apply to any contract between us for the supply of the Service to you (Contract).&nbsp; References in these Terms to the Service includes the content of our site (<strong>Content</strong>).&nbsp; Please read these Terms carefully and make sure that you understand them, before registering on our site.&nbsp; <strong>Please note that by registering on our site, you agree to be bound by these Terms and the other documents expressly referred to in them.</strong></p>
<p>Please check the relevant box on the registration page if you accept these Terms.&nbsp; If you refuse to accept these Terms, you will not be able to register on our site.</p>
<p>You should print a copy of these Terms or save them to your computer for future reference.</p>
<p>We may amend these Terms from time to time as set out in clause 6.</p>
<p>Please note that these Terms, and any Contract between us, are only in the English language.</p>
<p>1. Information about us</p>
<p>1.1 We operate the website <Link to="/home">www.raterental.co.uk</Link>.&nbsp; We are Ask Tenants Limited (<strong>we </strong>or <strong>us</strong>), a limited company registered in England and Wales under company number 0923802 and with our registered office and main trading address at 20-22 Wenlock Road London N1 7GU United Kingdom</p>
<p>To contact us, please see our Contact Us page <a href="https://raterental.co.uk/contact-us/">https://raterental.co.uk/contact-us/</a></p>
<p>2. Interpretation</p>
<p>2.1 Definitions.&nbsp; In these Terms, the following definitions apply:</p>
<p>Application<strong>: </strong>&nbsp;the online form available on our site, which you complete and submit to us when you register to use the Service, and which forms part of the Contract.</p>
<p>Business Day<strong>:</strong> a day (other than a Saturday, Sunday or public holiday) when banks in London are open for business.</p>
<p>Commencement Date<strong>:</strong> has the meaning set out in clause 3.3.</p>
<p>Contract<strong>:</strong> the contract between you and us for the provision of the Service in accordance with these Terms.&nbsp; The Contract includes the Application and the Terms (in each case as may be amended from time to time).</p>
<p>Intellectual Property Rights<strong>:</strong> all patents, rights to inventions, utility models, copyright and related rights, trade marks, service marks, trade, business and domain names, rights in trade dress or get-up, rights in goodwill or to sue for passing off, unfair competition rights, rights in designs, rights in computer software, database right, topography rights, moral rights, rights in confidential information (including know-how and trade secrets) and any other intellectual property rights, in each case whether registered or unregistered and including all applications for and renewals or extensions of such rights, and all similar or equivalent rights or forms of protection in any part of the world.</p>
<p>Policies:&nbsp; our policies in relation to use of our site generally, as posted on our site from time to time.</p>
<p>Service<strong>:</strong> the rental property ratings service for tenants and prospective tenants at <Link to="/home">www.raterental.co.uk</Link>.</p>
<p>Terms<strong>:</strong> these terms and conditions as amended from time to time in accordance with clause 6.</p>
<p>2.2 Construction.&nbsp; In these Terms, the following rules apply:</p>
<p>(a) a <strong>person</strong> includes a natural person, corporate or unincorporated body (whether or not having separate legal personality);</p>
<p>(b) references to the Service includes the content of the site (<strong>Content</strong>).</p>
<p>(c) a reference to a party includes its personal representatives, successors or permitted assigns;</p>
<p>(d) a reference to a statute or statutory provision is a reference to such statute or statutory provision as amended or re-enacted.&nbsp; A reference to a statute or statutory provision includes any subordinate legislation made under that statute or statutory provision, as amended or re-enacted;</p>
<p>(e) any phrase introduced by the terms <strong>including</strong>, <strong>include</strong>, <strong>in particular</strong> or any similar expression, shall be construed as illustrative and shall not limit the sense of the words preceding those terms; and</p>
<p>(f)&nbsp; a reference to <strong>writing</strong> or <strong>written</strong> includes faxes and emails.</p>
<p>3. Basis of contract</p>
<p>3.1 The Application constitutes your offer to subscribe to the Service in accordance with these Terms.&nbsp; We reserve the right to accept or decline any Application in our sole discretion.&nbsp; You submit your Application and accept these Conditions by checking the relevant box on the registration page of our site.</p>
<p>3.2 By registering on our site, you confirm that:</p>
<p>(a) if you are registering on behalf of a business or third party, you are legally capable of entering into binding contracts and are authorised by the legal entity or person on whose behalf you are registering to bind it to this Contract;</p>
<p>(b) if you are a sole trader or an individual acting in your personal capacity, you are at least 18&nbsp;years old; and</p>
<p>(c) you are the landlord or the lawfully appointed agent of the landlord of a property or properties situated in the UK.</p>
<p>3.3 After you go through the registration process you will enter details like your registered email address, property address, user ID. You must enter those details correctly. The website claims no responsibility for losses and damages due to you entering those details incorrectly.</p>
<p>3.4&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;The Contract between us will only be formed only when you accept our terms and conditions for landlords and create an account.</p>
<p>3.5 The Contract shall continue in force unless and until such time as the Contract is terminated by either you or us in accordance with clause 12.</p>
<p>3.6 By using our site, you agree to be bound by the Policies, our website terms of use <Link to="/tc's">https://raterental.co.uk/terms-of-use/</Link> acceptable use policy <Link to="/acceptable-use">https://raterental.co.uk/acceptable-use-policy/</Link> and privacy policy <Link to="/privacy">https://raterental.co.uk/privacy-policy/</Link> (in each case as in force from time to time), a current copy of each of which can be found on our site.</p>
<p>3.7 We shall be entitled to amend from time to time the Application, the Terms and the Policies or all or any of them, by notifying you by email or otherwise in accordance with clause 6.&nbsp; You acknowledge that it is your responsibility to check our site on a regular basis to keep yourself updated as to any changes we may make to all or any of the Application, the Term and the Policies.</p>
<p>3.8 <strong><em>This clause applies to businesses only. </em></strong>&nbsp;The Contract constitutes the entire agreement between you and us, and you acknowledge that you have not relied on any statement, promise or representation (whether made or given by us or on our behalf) that is not set out in the Contract.</p>
<p>3.9 <strong><em>This clause applies to businesses only. </em></strong>&nbsp;Any descriptive matter or advertising issued by us or displayed on our site is for the sole purpose of giving an approximate idea of the Service described in them.&nbsp; They shall not form part of the Contract or have any contractual force.</p>
<p>3.10 <strong><em>This clause applies to businesses only. </em></strong>&nbsp;These Terms apply to the Contract to the exclusion of any other terms that you may seek to impose or incorporate, or which are implied by trade, custom, practice or course of dealing.</p>
<p>4. Supply of the Service</p>
<p>4.1 We do not accept Applications in relation to properties situated outside the UK.</p>
<p>4.2 Following our acceptance of your Application, and subject to receipt of the Registration Details to our reasonable satisfaction and in compliance with the relevant provisions of clause 5 below, we will give you access to the Service, and you consent to our using the Registration Details for this purpose.</p>
<p>4.3 Although we aim to offer you the best service possible, we make no promise that the Service will meet your requirements, and we cannot guarantee that the Service will be fault free.&nbsp; If a fault occurs in the Service you should report it to us immediately by contacting us on <a href="mailto:info@raterental.co.uk">info@raterental.co.uk</a> and we will attempt to correct the fault as soon as we reasonably can.</p>
<p>4.4 Your access to the Service may occasionally be restricted to allow for repairs, maintenance or the introduction of new facilities or services.&nbsp; We will attempt to restore the service as soon as we reasonably can.</p>
<p>4.5 We reserve the right at any time to make any changes to the Service which are necessary to comply with any applicable laws or regulations, or which do not materially affect the nature or quality of the Service.</p>
<p>4.6 The Service does not include the provision of computer or other necessary equipment to access the Service.&nbsp; To use the Service you will require internet connectivity and appropriate telecommunication links.&nbsp; We will not be liable for any telephone or other costs that you may incur.</p>
<p>4.7 Registration for the Service is conducted in the English language only.</p>
<p>5. Your obligations</p>
<p>5.1 You agree to provide true, accurate, current and complete information about you and / or your business or the business of your principal (as the case may be) and any property in relation to which you are the landlord or the lawful agent of the landlord (<strong>Property</strong>), as prompted by the Service registration process (<strong>Registration Details</strong>), and to ensure that in the case of any information which includes personal data, such information is provided in compliance with the requirements of the General Data Protection Regulation and any associated laws regarding data privacy.&nbsp; You further agree that, in providing such Registration Details, you will not knowingly omit or misrepresent any material facts or information, and that you will promptly enter corrected or updated Registration Details via the Service, or otherwise advise us promptly in writing of any such changes or updates.&nbsp; You also agree to update the Registration Details (including, but not limited to, your current email address) as soon as they change.</p>
<p>5.2 By registering to use the Service, you acknowledge and agree that:</p>
<p>(a) by giving us details, comments, information, photographs and other images relating to the Property for publication on our site (the <strong>Property Information</strong>), you grant us a royalty-free, perpetual, irrevocable and non-exclusive right and licence to use, reproduce, distribute, display, modify and edit the Property Information in connection with the Services, and you warrant and represent that:</p>
<p>(i) you have all rights and authority necessary to grant us these rights (including, where applicable, any consents required under the General Data Protection Regulation and any associated laws regarding data privacy);</p>
<p>(ii) you are the landlord of the Property or the lawful agent of the landlord; and</p>
<p>(iii) the Property is a private dwelling or residence and is not a commercial, industrial, business, retail or agricultural property of any kind;</p>
<p>any Property Information you provide will comply in all respects with the provisions of our acceptable use policy <Link to="/acceptable-use">https://raterental.co.uk/acceptable-use-policy/</Link> including in particular but without limitation the content standards set out in that policy, and with our privacy policy <Link to="/privacy">https://raterental.co.uk/privacy-policy/</Link>;</p>
<p>(b) we retain the right at our sole discretion to remove from our site or edit the Property Information at any time;</p>
<p>(c) we are not an estate agency, and we merely provide an online service whereby tenants and prospective tenants may view information and reviews about rental properties, and the area in which those properties are situated; and</p>
<p>(d) you are solely responsible for preparing the Property Information in good faith, and for ensuring that it is complete and accurate in all respects, that it complies with all applicable laws (including without limitation the requirements of the General Data Protection Regulation and any associated laws regarding data privacy), and that it is not misleading in any way (including but without limitation in relation to the rental value of the Property, its state and condition, or its amenities).</p>
<p>5.3 You further agree that you will indemnify and keep us indemnified against any and all actions, costs, claims, demands, damages, expenses (including legal fees), liabilities, losses and proceedings in connection with your breach of these Terms (including but without limitation any breach of the provisions of this clause 5), or any claim by a third party in connection with a Property or any information given by you to us for publication on our site regarding a Property.</p>
<p>5.4 When you register to use the Service, you will need to enter your email address and password to access your account (<strong>Login Credentials</strong>).&nbsp; You agree that you will not allow another person to use your Login Credentials to access or use the Service under any circumstances.&nbsp; You agree that you are solely and entirely responsible for your Login Credentials and for any charges, damages, liabilities or losses incurred or suffered as a result of your failure to keep them secure.&nbsp; We are not liable for any charges, damages, liabilities or losses caused by or related to the theft of your Login Credentials, your disclosure of your Login Credentials, or you allowing another person to access and use the Service using your Login Credentials.</p>
<p>5.5 Your Login Credentials must not include any of the following:</p>
<p>(a) spaces or tabs;</p>
<p>(b) obscene or profane words;</p>
<p>(c) another user’s username;</p>
<p>(d) the &amp;, ‘, (, ), &lt;, or &gt; symbols;</p>
<p>(e) consecutive underscores (__);</p>
<p>(f)&nbsp; an underscore (_), dash (-) or full stop (.) at the beginning of a username; or</p>
<p>(g) the words “Ask Tenants”.</p>
<p>(h) Your email address or any other personal information.</p>
<p>5.6 You are solely and entirely responsible for any and all use of your account, including any charges incurred relating to the Service.&nbsp; You agree to immediately notify us of any unauthorised use of your account or any other breach of security known to you.</p>
<p>5.7 You acknowledge that the complete privacy of your data and messages transmitted over the internet while using the Service cannot be guaranteed.</p>
<p>5.8 You agree that you will not:</p>
<p>(a) register on our site as a tenant;</p>
<p>(b) post ratings (or arrange for third parties to post ratings on your behalf) in relation to a Property of which you are the landlord or the landlord’s appointed agent, or in relation to your business;</p>
<p>(c) post any material on our site in any format whatsoever (including without limitation, any comments, information, data, photographs or other images) which are in breach of our website terms of use https://raterental.co.uk/terms-of-use/ and / or our acceptable use policy <Link to="/acceptable-use">use policy</Link> &nbsp;or the Policies.</p>
<p>6. Licence</p>
<p>6.1 If you choose to do so, you may request from us a Licence to use the Ask Tenants “badge”, which is a logo provided in jpeg format (the <strong>Logo</strong>) for use solely in accordance with the licence terms set out below (the <strong>Licence</strong>).&nbsp; The Licence is granted on the basis that you shall use the Logo only:</p>
<p>(a) within your own website (and for the avoidance of any doubt not on any third party websites such as Facebook); and / or</p>
<p>(b) within your own promotional and marketing materials.</p>
<p>If you wish to use the Logo for any other purpose whatsoever, you must first contact us and obtain our prior written consent to such use.</p>
<p>6.2 You shall not use the Logo in any way than might indicate that we are solely or jointly responsible for your organisation, products, services, website or publications.</p>
<p>6.3 You must <strong>not </strong>use the Logo:</p>
<p>(a) as your own logo;</p>
<p>(b) in combination with, or as a part of your name or logo;</p>
<p>(c) with equal or greater prominence than that afforded to your own logo;</p>
<p>(d) in the “header” or “footer” sections of your website;</p>
<p>(e) on signage other than as part of an informational display;</p>
<p>(f)&nbsp; without sufficient context to indicate the purpose for its use;</p>
<p>(g) on a third party website;</p>
<p>(h) for any commercial purpose other than as set out in clause 6.1 above.</p>
<p>6.4 You shall include an acknowledgement on any printed matter, and on the highest (or first displayed) webpage within your domain displaying the Logo, to indicate that your use and reproduction of the Logo is under licence from us.&nbsp; A suitable wording is <strong><em>“Reproduced with permission of Ask Tenants Limited</em>”.</strong></p>
<p>6.5 You shall always use or reproduce the Logo in its entirety without any alteration or addition.</p>
<p>6.6 You use of the Logo must not adversely affect our rights in the Logo, or adversely affect our reputation.</p>
<p>6.7 You shall follow any reasonable instructions we give at any time concerning the use of the Logo.</p>
<p>6.8 We reserve the sole right to determine whether the terms of the Licence have been complied with and to terminate the Licence immediately in the case of breach.</p>
<p>6.9 We may otherwise terminate the Licence on giving 30 days notice without giving any specific reason.&nbsp; In the event that the Contract is terminated by either party for any reason pursuant to clause 12 below, the Licence will also automatically any without further notice terminate on the same date.</p>
<p>6.10 The rights granted under the Licence are personal to you and you shall not assign or sub-license such rights to others or permit others to use or reproduce the Logo in any way.</p>
<p>6.11 We give no warranties with respect to the suitability of the Logo for any purpose.</p>
<p>6.12 You shall indemnify us against any loss or claims arising from your use of the Logo.</p>
<p>7. Our right to vary these terms</p>
<p>7.1 We may revise these Terms from time to time in the event of any changes in relevant laws and regulatory requirements.</p>
<p>7.2 If we have to revise these Terms under clause 7.1, we will give you at least 30 calendar days’ month’s written notice of any changes to these Terms before they take effect.&nbsp; You can choose to cancel the Contract in accordance with clause 12.2.</p>
<p>8. Intellectual property rights</p>
<p>8.1 We (or our licensors, as applicable) own all Intellectual Property Rights in or arising out of or in connection with the Service, the Logo, and our site.</p>
<p>8.2 You hereby grant to us a non-exclusive, perpetual and irrevocable worldwide licence to publish the Property Information on our site, and to use such Property Information in our own editorial content or promotional materials in any medium, including on our site.</p>
<p>8.3 You acknowledge that, in respect of any third party Intellectual Property Rights in Property Information provided for use by us on our site or otherwise in connection with the Service (including text, data, photographs and other materials), our use of any such Intellectual Property Rights requires you to have first obtained a written licence from the relevant licensor on such terms as will either entitle you to licence such rights to us, or grant such rights direct to us.&nbsp; <strong>You warrant that you have obtained any and all such licences and shall provide copies of them to us on request.</strong></p>
<p>8.4 You shall indemnify us against all costs, claims, damages, losses and expenses arising as a result of any claim or action that the Property Information infringes Intellectual Property Rights belonging to a third party (<strong>IP Claim</strong>).</p>
<p>8.5 In the event of an IP Claim,</p>
<p>(a) we shall promptly notify you in writing of the claim or action;</p>
<p>(b) we shall make no admission or settlement without your prior written consent;</p>
<p>(c) we shall (subject to your reimbursement of our costs (including legal costs) and expenses) give you all the information and assistance that you may reasonably require; and</p>
<p>(d) we shall allow you complete control over any negotiations, litigation and settlement of any such claim or action.</p>
<p>9. Our status – IMPORTANT INFORMATION</p>
<p>9.1 We are not estate agents, and we merely provide an online service whereby tenants and prospective tenants may view information and reviews about rental properties, and the area in which those properties are situated.&nbsp; We are not a party to any negotiations in relation to, or rental of, any Property shown on our site, and we do not accept any responsibility or liability whatsoever (whether to you or to any third party) in relation to any such negotiations or contracts.</p>
<p>9.2 We may provide links on our site to the websites of other businesses, organisations, and public bodies, whether affiliated with us or not.&nbsp; We cannot give any undertaking that products or services you receive from third parties following a visit to our site, or from businesses to whose website we have provided a link on our site, will be of satisfactory quality.</p>
<p>10. Our liability to you if you are a consumer</p>
<p>10.1 <strong>We accept no liability whatsoever for any opinion, review, feedback, rating or other statement posted by third parties on our site in relation to you personally, your business, any Property, or otherwise contained in any of the Content (Third Party Material).&nbsp; Any views expressed do not necessarily represent our views.&nbsp; You acknowledge that it is reasonable for us to exclude our liability in relation to Third Party Material as the purpose of our site is to provide honest feedback from tenants regarding Properties, and by registering to use the Service you accept the risk of such Third Party Material representing you in a negative light.</strong></p>
<p>10.2 Subject to clause 10.1 above, if we fail to comply with the Terms, we will be responsible for loss or damage suffered by you that is a foreseeable result of our breach of the Terms or our negligence, but we are not responsible for any loss or damage that is not foreseeable.&nbsp; Loss or damage is regarded as foreseeable if it was an obvious consequence of our breach or if it was contemplated by you and us at the time the Contract was entered into.</p>
<p>10.3 We only supply the Service for domestic and private use.&nbsp; You agree not to use the Service for any commercial or business purpose, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</p>
<p>10.4 We do <strong>not </strong>exclude or limit in any way our liability for:</p>
<p>(a) death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors;</p>
<p>(b) fraud or fraudulent misrepresentation;</p>
<p>(c) breach of the terms implied by section 2 of the Supply of Goods and Services Act 1982 (title and quiet possession); and</p>
<p>(d) breach of the terms implied by sections 3, 4 and 5 of the Supply of Goods and Services Act 1982 (description, satisfactory quality, fitness for purpose and samples).</p>
<p>11. Our liability to you if you are a business</p>
<p>11.1 <strong>We accept no liability whatsoever for any opinion, review, feedback, rating or other statement posted by third parties on our site in relation to you personally, your business, any Property, or otherwise contained in any of the Content (Third Party Material).&nbsp; Any views expressed do not necessarily represent our views.&nbsp; You acknowledge that it is reasonable for us to exclude our liability in relation to Third Party Material as the purpose of the site is to provide honest feedback from tenants regarding Properties, and by registering to use the Service you accept the risk of such Third Party Material representing you in a negative light.</strong></p>
<p>11.2 Nothing in these Terms shall limit or exclude our liability for:</p>
<p>(a) death or personal injury caused by our negligence, or the negligence of our employees, agents or subcontractors;</p>
<p>(b) fraud or fraudulent misrepresentation; or</p>
<p>(c) any other matter which we are not by law entitled to exclude.</p>
<p>11.3 Subject to clauses 11.1 and 11.2:</p>
<p>(a) we shall under no circumstances whatever be liable to you, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, for any loss of profit, or any indirect or consequential loss arising under or in connection with the Contract; and</p>
<p>(b) our total liability to you in respect of all other losses arising under or in connection with the Contract, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, shall in no circumstances exceed £1,000.</p>
<p>12. Termination</p>
<p>12.1 We may terminate the Contract and cancel your registration immediately at our reasonable discretion if you breach any of your obligations under the Contract.</p>
<p>12.2 You can terminate the Contract and cancel your registration at any time by informing us in writing.&nbsp; If you do so, you must stop using the Service immediately.</p>
<p>12.3 The termination of the Contract and/or your right to use the Service and the Logo shall not affect either your or our rights or liabilities.</p>
<p>13. Discontinuation of Service</p>
<p>We reserve the right to discontinue the Service (or any part of it) without notice to you and shall not be liable to you if we exercise these rights.&nbsp; Where reasonably practicable, we will try to give you reasonable notice of any discontinuation of the Service.</p>
<p>14. General</p>
<p>14.1 Event Outside Our Control:</p>
<p>(a) For the purposes of this Contract, Event Outside Our Control means an event beyond our reasonable control including but not limited to strikes, lock-outs or other industrial disputes (whether involving our workforce or that of any other party), failure of a utility service or transport network, act of God, war, riot, civil commotion, malicious damage, compliance with any law or governmental order, rule, regulation or direction, accident, breakdown of plant or machinery, fire, flood, storm or default of suppliers or subcontractors.</p>
<p>(b) We will not be liable to you as a result of any delay or failure to perform our obligations under the Contract as a result of an Event Outside Our Control.</p>
<p>(c) If the Event Outside Our Control prevents us from providing all or part of the Service for more than 24 weeks, we will, without limiting our other rights or remedies, have the right to terminate the Contract immediately by giving written notice to you.</p>
<p>14.2 Assignment and subcontracting:</p>
<p>(a) We may at any time assign, transfer, charge, subcontract or deal in any other manner with all or any of our rights under the Contract and may subcontract or delegate in any manner any or all of our obligations under the Contract to any third party or agent.</p>
<p>(b) You shall not, without our prior written consent, assign, transfer, charge, subcontract or deal in any other manner with all or any of your rights or obligations under the Contract.</p>
<p>14.3 Notices:</p>
<p>(a) Any notice or other communication required to be given to a party under or in connection with this Contract shall be in writing and shall be delivered to the other party personally or sent by prepaid first-class post, recorded delivery or by commercial courier, at its registered office (if a company) or (in any other case) its principal place of business, or sent by fax to the other party’s main fax number.</p>
<p>(b) Any notice or other communication shall be deemed to have been duly received if delivered personally, when left at the address referred to above or, if sent by pre-paid first-class post or recorded delivery, at 9.00 am on the second Business Day after posting, or if delivered by commercial courier, on the date and at the time that the courier’s delivery receipt is signed, or if sent by fax, on the next Business Day after transmission.</p>
<p>(c) This clause 14.3 shall not apply to the service of any proceedings or other documents in any legal action.&nbsp; For the purposes of this clause, “writing” shall not include emails, and for the avoidance of doubt notice given under this Contract shall not be validly served if sent by email.</p>
<p>14.4 Waiver:</p>
<p>(a) A waiver of any right under the Contract is only effective if it is in writing and shall not be deemed to be a waiver of any subsequent breach or default.&nbsp; No failure or delay by a party in exercising any right or remedy under the Contract or by law shall constitute a waiver of that or any other right or remedy, nor preclude or restrict its further exercise.&nbsp; No single or partial exercise of such right or remedy shall preclude or restrict the further exercise of that or any other right or remedy.</p>
<p>(b) Unless specifically provided otherwise, rights arising under the Contract are cumulative and do not exclude rights provided by law.</p>
<p>14.5 Severance:</p>
<p>(a) If a court or any other competent authority finds that any provision of the Contract (or part of any provision) is invalid, illegal or unenforceable, that provision or part-provision shall, to the extent required, be deemed deleted, and the validity and enforceability of the other provisions of the Contract shall not be affected.</p>
<p>(b) If any invalid, unenforceable or illegal provision of the Contract would be valid, enforceable and legal if some part of it were deleted, the provision shall apply with the minimum modification necessary to make it legal, valid and enforceable.</p>
<p>14.6 Nothing in the Contract is intended to, or shall be deemed to, constitute a partnership or joint venture of any kind between any of the parties, nor constitute any party the agent of another party for any purpose.&nbsp; No party shall have authority to act as agent for, or to bind, the other party in any way.</p>
<p>14.7 A person who is not a party to the Contract shall not have any rights under or in connection with it.</p>
<p>14.8 Except as set out in these Terms, any variation, including the introduction of any additional terms and Terms, to the Contract, shall only be binding when agreed in writing and signed by us.</p>
<p>14.9 <strong><em>This clause only applies if you are a consumer.&nbsp; </em></strong>This Contract is governed by English law.&nbsp; You and we both agree to submit to the non-exclusive jurisdiction of the English courts.&nbsp; However, if you are a resident of Northern Ireland, you may also bring proceedings in Northern Ireland, and if you are a resident of Scotland, they may also bring proceedings in Scotland.</p>
<p>14.10 <strong><em>This clause only applies if you are a business.&nbsp; </em></strong>This Contract, and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims), shall be governed by, and construed in accordance with, English law, and the parties irrevocably submit to the exclusive jurisdiction of the courts of England and Wales.</p>

			</div>


        </div>
   
		
	</>
    );
}

export default Agency
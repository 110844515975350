import React,{ Component } from 'react';
// import { getJwt } from './jwt';
import axios from 'axios';
import  AutoCompPost from './autoCompPost'
import MongoPost from './autoCompPostBtt'
import Footer from './footerNew';
import { getJwt } from './jwt';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { notify } from 'react-notify-toast';
import CookieConsent, { Cookies } from "react-cookie-consent";
import '../css/styles.css';



class Rating extends Component {

    constructor(props) {
      super(props);

      

      this.state = {

        comments:{},
        recommendations:{},
        images:[],
        rating: {}
      }
    }

    


    maxSelectFile=(event)=>{
   let files = event.target.files // create file object
       if (files.length > 4) {
          const msg = 'Only 4 images can be uploaded at a time'
          event.target.value = null // discard selected file
          console.log(msg)
          // notify.show(msg)
         return false;

     }
   return true;

}

// toastfileErr = () => {
//   toast.error('Success Notification !', {
//     position: toast.POSITION.TOP_RIGHT
// }


checkMimeType=(event)=>{


  //getting file object
  let files = event.target.files
  //define message container
  let err = ''
  // list allow mime type
 const types = ['image/png', 'image/jpeg', 'video/mp4' ]
  // loop access array
  for(let x = 0; x<files.length; x++) {
   // compare file type find doesn't matach
       if (types.every(type => files[x].type !== type)) {
       // create error message and assign to container
       err += files[x].type+' is not a supported image/video format\n';
       let toastfileErr = () => {
        toast.error(`ERROR! ${files[x].type} + 'is not a supported image/video format'`, {
          position: toast.POSITION.TOP_CENTER
      })
    }
       toastfileErr()
      //  const msg = () => toast(`${files[x].type} + 'is not a supported image/video format'`)
       
     }
   };

 if (err !== '') { // if message not same old that mean has error
      // const msg = () => toast(`${err} + 'is not a supported image/video format'`)
      event.target.value = null // discard selected file
      console.log(err)
      
       return false;
  }
 return true;

}


onFileChange=event=>{
      // var element = document.getElementById('images')
      // var file = element.files[0].name
      // var fileName =file.name.toString().replace(/[_\)\(\s]/g,"")
      // // var newFile = new File([blob],'')
      // console.log('%c Image ById','color:red; font-weight:bold;')
      // console.log(fileName)
      // var images = event.target.files
      if(this.maxSelectFile(event) && this.checkMimeType(event)){
        console.log('%c Events','color:red; font-weight:bold;')
        

      // if return true allow to setState
         this.setState({
         images: [...this.state.images, ...event.target.files]
      })

   }
}

fetchAddress = () => {

  const addressId = this.props.id
  console.log(addressId)
  //Get the address in  corrWhat ect format
  // event.preventDefault();
  const id = toast.loading("Uploading Review...Please wait")
    const API_URL = `${process.env.REACT_APP_API_ADDRESS}/user/getaddress/address/${addressId}`;
    axios.get(API_URL)
      .then((response) => {
        
        var address = response.data;
        const line1 = address.line_1;
        const line2 = address.line_2
        const line3 = address.line_3
        const town = address.town_or_city
        const county = address.county
        const postcode = address.postcode
        // let image = [...images]

        // console.log(addImages.map((img,cur) => img.toString().replace(/[_\)\(\s]/g,"-")))

        // console.log(image);
        // console.log(addRatings);



        // setSplitAddress(response.data);
        // setAddressId(addressId)
        console.log('%c TOWN','color:yellow; font-weight:bold;')
        console.log(town);

        // get our form data out of Get response
        const formData = new FormData()
  
        const addImages = this.state.images;
        const addComments = this.state.comments;
        const addRecommendations = this.state.recommendations;
        const addRatings = this.state.rating

        console.log('%c AddComments','color:orange; font-weight:bold;')
        console.log(addComments)

        Object.keys(addImages).forEach((key) => {
          if (addImages[key] instanceof FileList) {
            console.log('%c AddImage','color:red; font-weight:bold;')
            console.log(formData.get(addImages));
            formData.append('images', addImages[key][0], addImages[key][0].name);
          } else {
            formData.append('images', addImages[key]);
          }
        });

        formData.append('line1', line1);
        formData.append('line2', line2);
        formData.append('line3', line3);
        formData.append('town', town);
        formData.append('county', county);
        formData.append('postcode', postcode);
        formData.append('addressId', addressId);
        formData.append('comments', addComments);
        formData.append('recommendations', addRecommendations);
        formData.append('ratings', addRatings);
        formData.append('images', addImages);

        // formData.append('images', JSON.stringify(images));
        // formData.append('images1',images1);
        // formData.append('images2',images2);

        const jwt = getJwt();
        let config = {
          headers: {
            'Content-Type': 'multipart/formData',
            'Authorization' : 'Bearer ' + jwt

          }
        }

        console.log(formData.get('comments'))

        axios.post(process.env.REACT_APP_API_ADDRESS + '/tenants/rate', formData, config)
        .then(response => {
          toast.update(id, {render: "Review uploaded successfully", type: "success", isLoading: false})
        console.log(response)

      })

      .catch((error) => {
        const err = response.data.error
        toast.update(id, {render: `'Error: ${error.response.data.error}'`, type: "error", isLoading: false })
        console.log(error);
      })

  })
}


    onFileChange(e) {
        this.setState({ images: e.target.files })
        console.log(e.target.files);
    }



    onChange = (e) => {
  /*
    Because we named the inputs to match their
    corresponding values in state, it's
    super easy to update the state
  */
  this.setState({ [e.target.name]: e.target.value });
}



  render () {

    const getElements = () => {
      document.getElementById('images').click()
    
    };

    const styles = {
      display: "none"
  };

  const styling = {
    display: "block",
    width: "120px",
    height: "30px"
};
  return (
<div>
 <section>
  <div className="rating">
    
        
    <div className='rate-comments'>
      <input type="text" placeholder="Your experience" name="comments" onChange={this.onChange}/><br />
      <br />
      <br />
      <input type="text" placeholder="Your recommendation" name="recommendations" onChange={this.onChange}/><br />
      <br />
      <br />
      <label htmlFor="stars">Rating (between 1 and 5):</label>
      <input type="number" encType="multipart/form-data"id="stars" name="rating" min="1" max="5" onChange={this.onChange}/>
      <br />
      <br />
   
      
      <button className='btn btn-primary form-control' style={styling} onClick={()=> {getElements();}}>Upload Images/Video</button>
      <div><small>Uploads limited to 3 images and 1 video <br/> 
         (png,jpeg,jpg and mp4)</small>
      </div>
      <div>{this.state.images.map((item,i) => <li key={i}>{item.name}</li>)}</div>
      <input type="file" encType="multipart/form-data" id="images" name="images" style={styles} multiple onChange={this.onFileChange} />
          <div className='rate-submit'>
            {/* <MongoPost posts={fetch} /> */}
            <MongoPost post = {() => {this.fetchAddress(this.addressId)}}/>
          </div>
  </div>
  </div>
 </section>
 <section>
    <div className='foot'>
      <Footer/>
    </div>
 </section>
 <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
</div>
)}
};


export default Rating;


export const API_URL = '//raterental.co.uk/api'




  export const GOOGLE_MAPS_API_USER = process.env.GOOGLE_MAPS_API_USER
  export const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY
  export const EMAIL_JS_SERVICE_ID = process.env.EMAIL_JS_SERVICE_ID
  export const EMAIL_JS_PUBLIC_KEY = process.env.EMAIL_JS_PUBLIC_KEY

import Perfect from "../assets/Tenants_Perfect_Property.jpg"
import '../css/custom2.css'
import '../css/styles.css'

export default function Mission () {
    return (
            <div className="grid-container-mission">
                <div className="mb-[15%] sm:mb-[5%]"><img src={Perfect} width={'100%'} height={'100%'} alt={"A couple sitting on the floor looking at their new home"}/></div>

                <div className="mb-[5%] mt-[5%]">
                    <h2> Our Mission </h2>
                    <br/>
                    <p> The property rental market is growing and it's important for Landlords and Agencies to be competent, reliable, fair and provide a quality service to their tenants. If you are looking for a suitable property to rent with
                        Raterental allows potential tenants to search reviews of previous tenants of a property. 
                        If you have lived in a rental property, then help potential tenants move with confidence by submitting a rating. Let others know about your experience as everyone deserves a special place to live!
                    </p>
                </div>

            </div>
    )
}
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './components/errorBoundary';
import { HelmetProvider } from 'react-helmet-async'


ReactDOM.render(
  <GoogleOAuthProvider clientId="474990308337-8qn00udp0a6ebhuh5q2o9c8ime2189f8.apps.googleusercontent.com">
  <React.StrictMode>
  <ErrorBoundary fallback='There was an Error. Please refresh the page'>
    <Router>
      <HelmetProvider>
      <App />
      </HelmetProvider>
    </Router>
    </ErrorBoundary>
  </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useState, useEffect, useRef } from "react";
import { useHistory } from 'react-router-dom';
import axios from 'axios';
// import { getJwt } from './jwt';
import MongoCardPost from './autoCardCompPostBtt'
import SignupCardRed from './autoCardCompRedBtt'
// import HeroImage from "./hero";
import SearchCardRed from './autoCardCompRefBtt'
import AddressCardList from './addressCardList'
import AddressCardPreview from './addressCardPreview'
import FinalAddress from './finalAdress'
import FinalAddressRender from './finalAddressRendCopy'
import StarRating from './starRating'
import HowTo from "./howTo";
import Footer from "./footerNew";
import Banner from '../assets/Clients&Agents2.jpg'
import Judge from '../assets/AdobeStock_552648480-1.jpg'
import Mission from "./ourMission";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Logo from '../assets/Rate Rental Logo-7.png';
import { faUser, faStar, faMagnifyingGlass, faMagnifyingGlassLocation, faComment } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Helmet } from "react-helmet-async"





// import { notify } from 'react-notify-toast';

import '../css/custom2.css'
import '../css/styles.css'


const AutoCardCompGet = (props) => {

  const searchRef = useRef(null); //represents search section
  const propImages = props
  const addImages = propImages.images
  const addComments = propImages.comment
  const addRecommendations = propImages.recommend

  const history = useHistory();


   const [showSuggestions, setShowSuggestions] = useState([]);
   const [postCode, setPostcode] = useState ("");
   const [getAddress, setGetAddress] = useState ([])
   const [filtSuggestions, setFiltSuggestions] = useState([]);
   const [compState, setCompState] = useState ("");
   const [splitAddress, setSplitAddress] = useState ([]);
   const [postcodeAddresses, setPostcodeAddresses] = useState([]);
   const [addressId, setAddressId] = useState([]);
   const [comment, setComment] = useState ("");
   const [recommendation, setRecommendation] = useState ("");
   const [searchAddress, setSearchAddress] =  useState ([])
   const [finalAddress, setFinalAddress] = useState ([]);
   const [images, setImages] = useState ([]);
   const [images1, setImages1] = useState ([]);
   const [images2, setImages2] = useState ([]);
   const [addressExists, setAddressExists] = useState (true)
   const [renderAddressList, setRenderAddressList] = useState (false)
   const [container, setContainer] = useState(false);
   const [addresses, setAddresses] = useState ([0]);
   const [ratings, setRatings] = useState ([0]);
   const [sortArrayUsr, setArrayUsr] = useState ([]);
   const [joinArrayUsername, setJoinArrayUsrname] = useState ([]);

   // const newWindow = useRef(window);
   // const removeWhitespace = (postCode: string) => {
   //      return postCode.replace(/\s/g, "")};


   useEffect(() => {
     // const convertedPostcode = removeWhitespace(
     //      postCode
     //    );
     const loadAddresses = async () => {
      //  const response = await axios.get(`https://api.getaddress.io/autocomplete/${postCode}?api-key=${API_KEY}`, { params: {filter:postCode, all: true}});
      const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/user/getaddress/${postCode}`)
      .catch((error) => { 
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response);
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      })
        //  setShowSuggestions(response.data)
        //  setShowSuggestions(response.data.suggestions)
            setShowSuggestions(response.data.suggestions)
        
        //  setFiltSuggestions(response.data)
        //  setPostcodeAddresses(response.data.suggestions)
         console.log('%c, Suggestions Res', 'color:red; font-weight: bold')
         console.log(response.data);
         console.log(postCode);

         console.log('%c, Array Res', 'color:orange; font-weight: bold')
        //  const suggestionsArray = response.data.suggestions
        const suggestionsArray = response.data
         setFiltSuggestions(response.data.suggestions)

        //  console.log(Array)
          } 

   loadAddresses();

 }, [postCode])

//  useEffect(() => {
//   toast.promise(fetchAddress, {
//     pending: "Promise is pending",
//     success: "Promise  Loaded",
//     error: "error"
//   });

//  },[])


 const onSuggesthandler = (selectedAddress) => {
   setPostcode(selectedAddress);
  //  setCompState (selectedAddress)
     setPostcodeAddresses(selectedAddress)
 }

const initialiseState = (selectedAddress) => {
  if (selectedAddress === setCompState) {
  setFiltSuggestions([])
  }
}

  const onChangeHandler = (postCode) => {
    let matches = []
 
    
    if (showSuggestions) {
       matches = filtSuggestions.filter(suggestion=>{
        // const regex = new RegExp (`${postCode}`,"gi")
        return setFiltSuggestions
      })
    }

    console.log('matches', matches);
    setFiltSuggestions(matches)
    setPostcode(postCode)   

  }

  const pushState = (obj, url) =>
  window.history.pushState(obj, '', url);

  const populateAddress = (setCompState) => {
    postcodeAddresses(setCompState)
}



const fetchHandler = (e) => {
  if (e && e.preventDefault) {
      e.preventDefault();
  }
  // e.preventDefault();
  fetchAddress()

}

const maxSelectFile=(event)=>{
 let files = event.target.files // create file object
     if (files.length > 4) {
        const msg = 'Only 4 images can be uploaded at a time'
        event.target.value = null // discard selected file
        console.log(msg)
        // notify.show(msg)
       return false;

   }
 return true;

}

const checkMimeType=(event)=>{
//getting file object
let files = event.target.files
//define message container
let err = ''
// list allow mime type
const types = ['image/png', 'image/jpeg']
// loop access array
for(let x = 0; x<files.length; x++) {
 // compare file type find doesn't matach
     if (types.every(type => files[x].type !== type)) {
     // create error message and assign to container
     err += files[x].type+' is not a supported format\n';
     const msg = `${files[x].type} + 'is not a supported format'`
     // notify.show(msg)
   }
 };

if (err !== '') { // if message not same old that mean has error
    event.target.value = null // discard selected file
    console.log(err)
     return false;
}
return true;

}

const showToastMessage = () => {
  let delay = 1000
  toast.info('No reviews found for this address !', {
      position: toast.POSITION.TOP_RIGHT,
        toastId: 'No Reviews',
        delay: delay
  });
};

const showToastMessageReviews = () => {
  let delay = 1000
  toast.info('Reviews Loaded !', {
      position: toast.POSITION.TOP_RIGHT,
        toastId: 'Reviews Found',
        delay: delay
  });
};

const onFileUpload= event => {
    // var images = event.target.files
    // if(maxSelectFile(event) && checkMimeType(event)){
    // if return true allow to setState
       setImages([...images, event.filelist])
 }
// }

const handleImageUpload = e => {
  // const tempArr = [];

  const img = [...e.target.files]

  setImages(img)
  
};

const fetchAddress = (event) => {
  if (event && event.preventDefault) {
    event.preventDefault();
  }
  console.log('fetchAdd Fired')
  // event.preventDefault();
  // const id = toast.loading("fetching Reviews")
  let timeOut = 3000
  //Get the address in  corrWhat ect format
    // const API_URL = `https://api.getAddress.io/get/${addressId}?api-key=${API_KEY}`;
    const API_URL = `${process.env.REACT_APP_API_ADDRESS}/user/getaddress/address/${addressId}`
    axios.get(API_URL)
      .then((response) => {
        // toast.update(id,{render: `${'Reviews Loaded'}`, toastId: 'Reviews Loaded1', autoClose: timeOut, type: "info", isLoading: false });
        var address = response.data;
        const line1 = address.line_1;
        const line2 = address.line_2;
        const line3 = address.line_3;
        const town = address.town_or_city;
        const county = address.county;
        const postcode = address.postcode;
        // const comment = address.comments;
        // const recommend = address.recommendations;
        let image = [...images]

        console.log('%c original GETADDRESS API Response','color:red; font-weight:bold;' )
        console.log(address);



        const  removeWhitespace = (postcode: string, line1: string, line2: string, line3: string, town: string ) => {
            return postcode.replace(/\s/g, "");
          }
        const uppPostcode = (postcode: string, line1: string, line2: string, line3: string, city: string, town: string) => {
          return postcode.toUpperCase();
        }


        const convertedPostcode5 = removeWhitespace(
        county
        );

        const convertedPostcode3 =town



        const convertedPostcode4 = removeWhitespace(
          line3,
        );


          const convertedPostcode6 =line1

          const convertedPostcode =postcode


          const convertedPostcode2 =

            line2
          const uppPostcode1 =
          uppPostcode(convertedPostcode)

          const uppPostcode2 =
          uppPostcode(convertedPostcode2)

          const uppPostcode3 =
          uppPostcode(convertedPostcode3)

          const uppPostcode4 =
          uppPostcode(convertedPostcode4)

          const uppPostcode5 =
          uppPostcode(convertedPostcode5)

          const uppPostcode6 =
          uppPostcode(convertedPostcode6)

          let data = []

          console.log(line1);
          


       axios.get (`${process.env.REACT_APP_API_ADDRESS}/tenants/search?search_value=${convertedPostcode}&search_value1=${convertedPostcode6}&search_value2=${convertedPostcode3}`)

        .then((response) => {
          // toast.update(id,{render: `${'Reviews Loaded'}`, toastId: 'Reviews Loaded1', autoClose: timeOut, type: "info", isLoading: false });

          let apiResponse = JSON.parse(JSON.stringify(response));
          console.log('%c original API Response','color:red; font-weight:bold;' )
          console.log(apiResponse)
          // let apiResponse = response
 
          //Push state on re-direct ?
  history.push({
    pathname: "/user-review-preview",
   state:{
     response: apiResponse
   }})
  }).catch((error) => {
  //   toast.update({render:`${error.response.data.error}`, type: "error", isLoading: false})
  // console.log(error.response);
  // console.error(error.response.data);    // ***
  // console.error(error.response.status);  // ***
  // console.error(error.response.headers); // <- that's your response data)
  setAddressExists(false)
  })
  return data;
})

.catch((error) => {
  toast.update({render:`${error.response.data.error}`, type: "error", isLoading: false})
console.log(error.response);
console.error(error.response.data);    // ***
console.error(error.response.status);  // ***
console.error(error.response.headers); // <- that's your response data)
setAddressExists(false)
})
}


// const code = searchAddress.postcode
// const pCode = searchAddress.map(add => code.push(add.postcode))
// console.log(code);


const fetchTheAddress = (addressId) => {
  // const id = toast.loading("fetching Reviews")
    axios.get(`${process.env.REACT_APP_GET_ADDRESS_API_KEY}/tenants/${addressId}`)
      .then((response) => {
        // toast.update(id,{render: `${response.data.data.message}`, type: "info", isLoading: false});
      
        const address = response.data.address;
        const images = address.images;
        const comments = address.comments;
        const recommendations = address.recommendations;
        const ratings = address.ratings;
        const ratObj = address.ratingObj;
        // const username = address.postedBy.username
        console.log(address);
        console.log(ratings);
        //const username = localStorage.getItem('username');

        //Merges recommendations and comments array

        const comRecRat = [...comments,...recommendations,...images]

        console.log(comRecRat);

        //Function to sort comments and recommendations by username key

        let groupBy = function(comRecRat, key) {
  return comRecRat.reduce(function(result, item) {
    (result[item[key]] = result[item[key]] || []).push(item);
    return result;


  }, {});
};

//stores result of above function in variable
let groupedUsrname = groupBy(comRecRat, 'author')



let merged = [];

for(let i=0; i<comments.length; i++) {
  merged.push({
   ...comments[i],
   ...(recommendations.find((itmInner) => itmInner.author === comments[i].author)),
   ...(images.find((itmInner) => itmInner.author === comments[i].author))}
  );
}

console.log(merged);

setArrayUsr(merged);
console.log(groupedUsrname);
console.log(sortArrayUsr);






      setFinalAddress(address);


        setRenderAddressList(true)
        setContainer(true)

        setAddressExists(false)
        setRatings(ratings)



        pushState(
          { currentAddressId: addressId },
          `/tenants/address/${addressId}`
        );
      })


      .catch(err => {
        // toast.update(id, {render: "Something went wrong", type: "error", isLoading: false });
        console.log(err)
   });
  };





const inputRef = useRef()

function handleBackClick() {
  // Scroll back to the title element...
  inputRef.current.scrollIntoView({ 
    top: 0,
    right: 0,
    behavior: 'smooth' })
    inputRef.current.focus()
}


const noAddressList = () => {
  if (addressExists) {
    console.log("Executed");
    setAddressExists(false)
  }
}

const redirect = () => {
   history.push({
      state:{
        address: sortArrayUsr,
        add: searchAddress,
        rate: ratings,
        imgs: images2
      }});
 }




   return (
    <>
    <Helmet>
      <title>Home</title>
      <meta name="description" content="Create or search a ex-tenants review of a rented property" />
      <link rel="canonical" href="http://raterental.co.uk/"/>
    </Helmet>
    <div className='home-wrapper'>
     <section className='showcase-container'>
     <div className='showcase-inner'>
     <div className = 'showcase-items'>
      <div className="showcase-text">
     <div className="title-main"><h1><b>Read Rental Reviews.</b></h1></div>
     <div className="title-main"><h1><b>Write Rental Reviews.</b></h1></div>
     <div className="title-main"><h1><b>Rent With Confidence.</b></h1></div>
     </div>
     </div>
     <div className = 'showcase-form-2 cardie-home'>
      <form onSubmit={fetchAddress}>
        <div className='form-control'>
          <div>
        <input ref ={inputRef}
         placeholder="Enter the postcode"
          type="text"
          onChange={e => onChangeHandler (e.target.value)}
          value={postCode}
          
          onBlur={()=> {
              setTimeout(()=> {
              setFiltSuggestions([])
            }, 500);

        }}
        />
          <div className='filt-suggestions'>
          <br/>
        {filtSuggestions && filtSuggestions.map((suggestion, index)=>
        <div key={index} className="suggestions"
        onClick={()=>{onSuggesthandler(suggestion.address); initialiseState(suggestion.address); setAddressId(suggestion.id);}}>{suggestion.address}</div>)}
        </div>
        </div>
      
        </div>
        <div>

        <div>
        <button className="btn btn-primary form-control" onClick={()=>{fetchHandler()}}>
          Search
        </button>
        </div>

        </div>
        </form>
        </div>
        </div>
       <div>
           {!addressExists && !container ? 
           <div>{showToastMessage()}</div> :
           <div>{showToastMessageReviews}</div>
       } </div>
        <div>
           {
             sortArrayUsr.length >0 ? <div>
             <AddressCardPreview address={sortArrayUsr}
             add = {searchAddress}
             img = {images2}
             onAddressClick={fetchTheAddress}
             rating={ratings}
             /> </div> : null
           }
          </div>
          <div>
           {
             container && ratings.length >0 ? <div>
        <FinalAddressRender address={finalAddress} groupcoms ={sortArrayUsr} />
        <StarRating rating={ratings}/>

       </div> : container ? <div>
  <FinalAddressRender address={finalAddress} groupcoms ={sortArrayUsr} />
</div>

 :  container && ratings ? <div>
<FinalAddressRender address={finalAddress} />
<StarRating ratingNums={ratings}/>
</div>

  :null

           }
    
  <div>
    <ToastContainer />
  </div>




    </div>
    </section>


    <section className='how-to-rate'>
      <div className='show-container'>
           <Mission/>
      </div>
    </section>
      <section className='how-to-guide'>
        <div className="show-container-guide grid-3 text-center myy-4">
          <div className="signup-text">
            <div className="fontawesome-icons">
              <FontAwesomeIcon icon={faUser} size="lg" />
            </div>
            <span className="break"></span>
            <h3>Sign Up</h3>
            <p> To create an account click on "Sign Up Here".  
            Fill in the required fields. Create the account.
            Click the link in the verification email. 
            Altenatively sign in with your Google account.
            </p>
            <br/>

          </div>
          <div className="rate-property-text">
            <div className="fontawesome-icons">
              <FontAwesomeIcon icon={faStar} size="lg" />
            </div>
          <span className="break"></span>
            <h3>Rate Property</h3>
            <p> Your recommendations are important for potential tenants. 
                To rate a property login to your account. Complete the required fields. 
                Upload pictures and video. Submit your rating.
          </p>
          <SignupCardRed/>
          </div>
          <div className="search-rating-text">
            <div className="fontawesome-icons">
              <FontAwesomeIcon icon={faMagnifyingGlass} size="lg" />
            </div>
          <span className="break"></span>
            <h3>Search Rating</h3>
            <p> Your rating is now searchable and accessible
            for the property. In the search field enter the postcode
            and click the "search" button. Your review will be listed.
            </p>
            <br/>
            
          </div>
        </div>
    </section>
    <section className='be-the-judge'>
           <HowTo/>
    </section>
    <section className="search-container">
      <div>

      </div>
       <div className = "search-button" >
           <SearchCardRed OnRefClick = {handleBackClick}/>
          </div>
     </section>
     <section className="foot-container">
     <div>
        <Footer/>
      </div>
     </section>
        <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
    </div>

    </>
  )};

export default AutoCardCompGet;
